.kontakt-section{
    padding-top: 110px;
    padding-bottom: 105px;
    .h3{
        margin-bottom: 25px;
        opacity: 0;
        top:10px;
        position: relative;
        transition: all 0.45s ease-in-out 1.25s;
    }
    .button{
        opacity: 0;
        top:10px;
        position: relative;
        transition: all 0.45s ease-in-out 1.5s;
    }

    @include breakpoint(medium down) {
        padding-top: 50px;
        padding-bottom: 45px;
    }

    &.animate{
        .h3,.button {
            opacity: 1;
            top: 0px;
        }
    }
}
.referenzen{
/*    max-height: 585px;*/
    background: url(../img/pattern_onblue.png) no-repeat;
    background-color: $medium-blue;
    background-position: 120% -65px;
    @include breakpoint(medium down) {
        max-height:inherit;
    }
    padding-top: 0px;
    position: relative;
    .row{
        position: relative;
    }
    .head-referenzen{
        margin-top:45px;
        @include breakpoint(medium down) {
            .h2{
                margin-bottom: 0rem;
            }
        }
    }

    .bg-circle{
        background: url(../img/circles_white_trans.png) no-repeat -160px 80px;
        width: 100%;
        height: 100%;
        left:0px;
        bottom: 0px;
        position: absolute;
        z-index: 1;
        @include breakpoint(large down) {
            display: none;
        }
    }

    .row{
        z-index: 2;
    }
}

.headline-only {
    .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 0;
    }
        background: url(../img/pattern_onblue.png) no-repeat;
    background-color: $medium-blue;
    background-position: 120% -65px;
}

.content {
    &.strategy , &.engagement {
        padding-top: 60px;
        margin-bottom: 45px;
            background: url(../img/pattern_onblue.png) no-repeat;
    background-color: $medium-blue;
    background-position: 120% -65px;
    }
}