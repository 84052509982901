@keyframes blurIn {
    0% {
        filter: blur(15px);
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        filter: blur(0px);
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes blurOut {
    0% {
        filter: blur(0px);
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        filter: blur(15px);
        transform: translateY(20px);
        opacity: 0;
    }
}

$blur-in-out-fade-duration: 1s;
$blur-in-out-display-duration: 3s;
@keyframes blurInOut {
    $fade-duration: $blur-in-out-fade-duration;
    $display-duration: $blur-in-out-display-duration;
    $animation-duration: (2 * $fade-duration) + $display-duration;

    $blur-in-finish-percentage: percentage($fade-duration / $animation-duration);
    $blur-out-start-percentage: 100% - percentage($fade-duration / $animation-duration);

    0% {
        filter: blur(15px);
        transform: translateY(20px);
        opacity: 0;
    }
    #{$blur-in-finish-percentage} , #{$blur-out-start-percentage} {
        filter: blur(0px);
        transform: translateY(0px);
        opacity: 1;
    }

    100% {
        filter: blur(15px);
        transform: translateY(20px);
        opacity: 0;
    }
}

.animation-blurIn{
    position: relative;
    animation: blurIn 1.5s ease-in-out forwards;
    &.fulls-c-2, &.fulls-c-3{
        @include breakpoint(small down) {
            animation:none;
        }
    }
}

.animation-blurOut{
    position: relative;
    animation: blurOut 1.5s ease-in-out forwards;
}


.animation-content-blur-start{
    & > div {
        opacity: 0;
    }
}

.animation-content-blur-2{
    position: relative;
    opacity: 0;
    @include breakpoint(medium down) {
        opacity: 1;
    }
}

.animation-content-blur-3{
    position: relative;
    opacity: 0;
    @include breakpoint(medium down) {
        opacity: 1;
    }
}


.animation-loop {
    position: relative;

    .animation-loop-part {
        position: absolute;
        opacity: 0;
        width: 100%;
        max-width: 100%;
        
        &.active {
            animation: {
                name: blurInOut;
                duration: (2 * $blur-in-out-fade-duration) + $blur-in-out-display-duration;
                iteration-count: 1;
            }
        }
    }
}