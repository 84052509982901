
@keyframes growUpCircle {
    0% {width: 40px; height: 40px;}
    30% {width: 35px; height: 35px; top:-2.5px; }
    90%{width: 50px; height:50px; top:5px; }
    100% {width: 45px; height: 45px; top:2.5px; }

}

@keyframes growDownCircle {
    0% {width: 45px; height: 45px; top:2.5px;}
    30% {width: 50px; height:50px; top:5px; }
    90%{width: 35px; height: 35px; top:-2.5px; }
    100% {width: 40px; height: 40px; }
}

@keyframes animateUpDownArrow {
    0% {top:50%; opacity:1; }
    50% {top:56%; opacity:0.5;}
    100% {top:50%; opacity: 1;}
}

@keyframes animateRightLeftArrow {
    0% {right:50%; opacity:1; }
    50% {right:44%; opacity:0.5;}
    100% {right:50%; opacity: 1;}
}
@keyframes animateLeftRightArrow {
    0% {left:50%; opacity:1; }
    50% {left:44%; opacity:0.5;}
    100% {left:50%; opacity: 1;}
}

$growAnimationSpeed: 0.4s;

.icon-circle-arrow{
    display: inline-block;
    border-radius: 50%;
    text-align: center;

    .title{
        position: absolute;
        top: -20px;
        width: 200px;
        margin-left: -100px;
        left: 50%;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 12px;

        @include breakpoint(large down) {
            top:-15px;
        }
    }

    .bottom, .top, .left, .right{
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 2px solid $dark-blue;
        border-radius: 50%;
        position: relative;

        transition: all 0.4s ease-in-out;

        &:after {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-top: 2px solid $dark-blue;
            border-right: 2px solid $dark-blue;

            transition: all 0.4s ease-in-out;

        }
        &:hover, &:active{
            border: 2px solid $medium-blue;

            &:after{
                border-top: 2px solid $medium-blue;
                border-right: 2px solid $medium-blue;

                animation: animateUpDownArrow 1.5s infinite 0.5s;
            }
        }
    }

    .close {
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 2px solid $dark-blue;
        border-radius: 50%;
        position: relative;

        transition: all 0.4s ease-in-out;

        &:after {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-top: 2px solid $dark-blue;
            border-right: 2px solid $dark-blue;

            transition: all 0.4s ease-in-out;
        }

        &:hover, &:active{
            border: 2px solid $medium-blue;

            &:after{
                border-top: 2px solid $medium-blue;
                border-right: 2px solid $medium-blue;
            }
        }
    }


    .right {
        &:hover, &:active{
            &:after{
                animation: animateRightLeftArrow 1.5s infinite 0.5s;
            }
        }
    }

    .left {
        &:hover, &:active{
            &:after{
                animation: animateLeftRightArrow 1.5s infinite 0.5s;
            }
        }
    }

    .bottom{
        &:after{
            position: absolute;
            left: 50%;
            top:50%;
            margin-left: -6px;
            margin-top: -8px;
            transform: rotate(135deg);
        }
    }
    .top{
        &:after{
            position: absolute;
            left: 50%;
            top:50%;
            margin-left: -6px;
            margin-top: -4px;
            transform: rotate(-45deg);
        }
    }
    .left{
        &:after{
            position: absolute;
            left: 50%;
            top:50%;
            margin-left: -4px;
            margin-top: -6px;
            transform: rotate(-135deg);
        }
    }
    .right{
        &:after{
            position: absolute;
            right: 50%;
            top:50%;
            margin-right: -4px;
            margin-top: -6px;
            transform: rotate(45deg);
        }
    }

    .close{
        &:after{
            position: absolute;
            left: 50%;
            top:50%;
            margin-left: -6px;
            margin-top: 2px;

            transform: rotate(-45deg);
        }


        &:before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-top: 2px solid $dark-blue;
            border-right: 2px solid $dark-blue;

            transition: all 0.4s ease-in-out;

            position: absolute;
            left: 50%;
            top:50%;
            margin-left: -6px;
            margin-top: -12px;

            transform: rotate(135deg);
        }
    }


    &.white {
        .bottom, .top, .left, .right, .close{
            border-color:$white;
            &:after{
                border-color:$white;
            }
            &:hover, &:active {
                border-color: $medium-blue;
                &:after {
                    border-color: $medium-blue;
                }
            }
        }
    }

    &.medium-blue {
        .bottom, .top, .left, .right{
            border-color:$medium-blue;
            &:after{
                border-color:$medium-blue;
            }
            &:hover, &:active {
                border-color: $dark-blue;
                &:after {
                    border-color: $dark-blue;
                }
            }
        }
        .close{
            border-color:$medium-blue;
            &:after{
                border-color:$medium-blue;
            }
            &:before{
                border-color:$medium-blue;
            }
            &:hover, &:active {
                border-color: $dark-blue;
                &:after {
                    border-color: $dark-blue;
                }
                &:before {
                    border-color: $dark-blue;
                }
            }
        }
    }
    &.dark-blue {
        .close{
            border-color:$dark-blue;
            &:after{
                border-color:$dark-blue;
            }
            &:before{
                border-color:$dark-blue;
            }
            &:hover, &:active {
                border-color: $medium-blue;
                &:after {
                    border-color: $medium-blue;
                }
                &:before {
                    border-color: $medium-blue;
                }
            }
        }
    }
}

.icn-mail{

}
.tel{
    color: $white;
}

.icon-key {
    $size: 25px;

    @include image-png('icns-key', $size, $size, $size, 100px);

    &.key-a {
        background-position: 0 0;
    }
    &.key-b {
        background-position: 0 (-$size);
    }
    &.key-c {
        background-position: 0 (-2 * $size);
    }
    &.key-d {
        background-position: 0 (-3 * $size);
    }
}