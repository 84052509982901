
.contact-overlay {
    position: relative;
    min-height: 800px;

    .intro {
        margin-bottom: 45px;
        h2 {
            font-size: rem-calc(24);
            margin-bottom: 20px;
        }
    }

    label{
        padding-left: 30px;

        @include breakpoint(small down) {
            padding-left: 0px;
        }
    }

    .submit-btn-wrapper{
        padding: 50px 0 50px 0;
    }

    .callback-wrapper{
        padding-top: 30px;
    }
    .gender-wrapper{
        padding-bottom: 20px;
    }

    .decoration {
        position: absolute;
        right: 15px;
        bottom: -120px;
        animation: animateOnigamiFlying 3s ease-in-out infinite 0s;
        @include breakpoint(medium down) {
            display: none;
        }
    }
}

.contact-success{
    display: none;
    opacity: 0;
    &.animate{
        display: block;
        animation: animateInOverlay 0.75s ease-in-out forwards;
    }
}

.contact-form-wrapper{
    &.animate{
        animation: animateOutOverlay 0.4s ease-in-out forwards;
        &.hide{
            display: none;
        }
    }
}
.origami-contact-wrapper{
    position: relative;
}