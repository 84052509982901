

/*
*   HTML, BODY
*/

html, body {
    height: 100%;
    color:$dark-gray;
}

.page-wrap {
    min-height: 100%;
    @include breakpoint(large down) {
        overflow: hidden;
    }
}

.content{
    padding-top: 25px;
    padding-bottom: 30px;

    @include breakpoint(medium up) {
        padding-top: 45px;
        padding-bottom: 60px;
    }

    .h1:first-child{
        margin-bottom: 2rem;
    }
    .h2:first-child{
        margin-bottom: 2rem;
    }

    &.headline-only {
        padding-top: 30px;
        padding-bottom: 30px;
        @include breakpoint(medium up) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    &.no-background + .content.no-background {
        padding-top: 0;
    }

    &.first{
        margin-top: 0;
        @include breakpoint(medium up) {
            margin-top: 120px;
        }
    }

    &.subpage {
        padding: {
            top: 60px;
            bottom: 60px;
        }
    }

    &.berater-stage {
        padding-bottom: 60px;
        @include breakpoint(medium up) {
            padding-bottom: 250px
        }
    }

    &.berater-content {
        padding-top: 0;
    }

    &.referenzen{
        @include breakpoint(medium up) {
            padding-bottom: 50px;
            min-height: 680px; //bei 3 Referenzen: 580px; bei 4 Referenzen: 680px usw. 
        }
    }

    &.berater{
        padding-bottom: 220px;
        position: relative;
        overflow: hidden;
        @include breakpoint(medium down) {
            padding-bottom: 300px;
        }
        .bg-circle{
            @include image-png ("circles_blue_trans", 534px, 733px);
/*            background: url(../img/circles_blue_trans.png) no-repeat bottom right;*/
;
            right: -150px;
            bottom: -350px;
            position: absolute;
            z-index: -1;
            @include breakpoint(medium down) {
                right: inherit;
                bottom: -500px;
            }
        }
        .container-berater{
            position: relative;
            .images-berater{
                position: absolute;
                right: -120px;
                bottom: -220px;
                .back{
                    background-image: url(../img/berater/berater_back.png);
                    background-repeat: no-repeat;
                    width: 423px;
                    height: 380px;
/*                    @include image-png ("berater/berater_back", 460px, 386px);*/
                }       
                .front{
                    background-image: url(../img/berater/berater_front.png);
                    background-repeat: no-repeat;
                    width: 329px;
                    height: 352px;
/*                    @include image-png ("berater/berater_front", 329px, 352px);      */
                    position: absolute;
                    right: 310px;
                    bottom: 0;
                    z-index: 1;
                }
                @include breakpoint(medium down) {
                    bottom: -425px;
                    right: -180px;
                    .back, .front{
                        background-size: 70%;
                    }
                    .front{
                        right: 280px;
                    }
                }
            }
        }
    }
    
    &.footer{
        padding-bottom: 15px;
    }
}

.origami-pattern{
    background-image: url(../img/pattern_ongrey.png);
    background-repeat: repeat-y;
    background-position: center center;
    &.c-bg-dark-gray{
        background-image: url(../img/pattern_onbrown.png);
    }
}

.origami-white-pattern {
    //background-image: url(../img/pattern_onblue.png);
    background-repeat: repeat-y;
    background-position: center center;
}
.circle-blue-pattern {
    background-image: url(../img/circles_blue_trans.png);
    background-repeat: repeat-y;
    background-position: right center;
}
