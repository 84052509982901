.arrow {
    $width: 67px;
    $height: 38px;

    position: relative;
    display: inline-block;

    &::before {
        content: '';
        position: absolute;
    }

    &.left {
        padding-left: $width + 20px;
        &::before {
            left: 0;
        }
    }

    &.right {
        padding-left: $width + 20px;
        &::before {
            left: 0;
        }

        &.large-up {
            @include breakpoint(medium up) {
                padding-left: $width + 20px;
                padding-right: 0;
                &::before {
                    left: 0;
                    right: auto;
                }
            }

            @include breakpoint(large up) {
                padding-left: 0;
                padding-right: $width + 20px;
                &::before {
                    left: auto;
                    right: 0;
                }
            }
        }

        @include breakpoint(medium up) {
            padding-left: 0;
            padding-right: $width + 20px;
            &::before {
                left: auto;
                right: 0;
            }
        }
    }

    &.flip {
        &::before {
            transform: scaleX(-1);
        }
    }

    @for $i from 1 to 7 {
        &.arrow-#{$i} {
            &::before {
                @include image-png('arrows/arrow-#{$i}', $width, $height);
            }
        }

    }

    &.arrow-1::before {
        top: calc((50% - #{$height}) + 17px);;
    }

    &.arrow-2::before {
        top: 4px;
    }

    &.arrow-3::before {
        top: calc((50% - #{$height}) + 19px);
    }

    &.arrow-4::before {
        top: calc((50% - #{$height}) + 11px);
    }

    &.arrow-5::before {
        top: calc((50% - #{$height}) + 13px);
    }

    &.arrow-6::before {
        top: calc((50% - #{$height}) + 18px);
    }
}
