

section.header {
    position: absolute;
    top:0px;
    // max-width: 1000px;
    width: 100%;
    // margin-left: -500px;
    // left:50%;
    z-index: 100;

    @include breakpoint(large down) {
        max-width: inherit;
        //position: relative;
        margin: auto;
        left: inherit;
    }

    @include breakpoint(small down) {
        position: relative;
    }

    .site-header {
        .wrapper-page {
            padding-top: 20px;
        }
        .logo-bar {
            .logo {
                @include image-png ("logo", 93px, 99px);
                background-repeat: no-repeat;
                display: inline-block;
                float: left;
            }
        }

        &.mobile{
            display: block;
            .logo{
                @include breakpoint(small down) {
                    width: 48px;
                    height: 50px;
                    background-size: auto 100%;
                }

            }


            @include breakpoint(large up) {
                display: none;
            }
        }

        &.desktop{
            display: none;
            @include breakpoint(large up) {
                display: block;
            }

        }
    }
}