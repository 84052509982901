.h1-beitraege{
    font-size: 24px;
    letter-spacing: 0.2em;
    margin-bottom: 0px;
    line-height: 1.6;
    font-family: 'Roboto-Medium';
    text-transform: uppercase;
}

.h3-beitraege{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 30px;
    font-size: 20px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
}



.clearfix:after {
    content: "";
    display: table;
    clear: both;
}



.fachbeitraege{
    &.content{
        &.subpage{
            padding-top: 30px;
            padding-bottom:30px;
            @include breakpoint(medium up) {    
                padding-top: 60px;
                padding-bottom:20px;
            }
        }
        p{
            margin-bottom:0px;
        }
    }

    .beitrag-header{
        position: relative;
    }
    p{
        &.leistung-text{
            min-height:0px;
        }
    }
    .bg-circle2{
        position: absolute;
        top: 0;
        right: 20px;
        @include image-png('circles-berater', 322px, 441px);
    }
    .teaser-text-right{
        @include breakpoint(medium up) {
            float:right;
            width:50%;
        }
        @include breakpoint(large up) {
            width:66%;
            padding-right: 16%;
        }
        .text-link{
            margin-bottom:0px;
        }
    }

    .left-container{
        @include breakpoint(medium up) {
            width:47%;
            float:left;
            margin-top:-53px;
        }
        @include breakpoint(large up) {
            width:30%;
        }
    }

    .right-container{
        @include breakpoint(medium up) {
            margin-top:7px;
            width:50%;
            float:right;
        }
        @include breakpoint(large up) {
            width:30%;
        }
    }

    .picture-left-container, .picture-right-container{
        opacity: 0;
        transform: translate(0px, 15px); 
        transition: all 0.5s ease-in-out 0.6s;
        background-color: $dark-blue;
        max-width: 320px;
        display:block;
        height:100%;

        @include breakpoint(medium down) {
            margin-bottom: 20px;
        }

        &.animate{
            opacity: 1;
            transform: translate(0px, 0px);
        }

        img{
            transition: all 0.4s ease-in-out;
            width:100%;
            &:hover{
                opacity: 0.75;
            }
        }
    }

    .picture-left-container{
        @include breakpoint(medium up) {               
            float:right;
        }
    }

    .teaser-text-left{
        @include breakpoint(medium up) {
            float:left;
            width:47%;
        }
        @include breakpoint(large up) {            
            width:66%;
            padding-left: 190px;
        }
        .text-link{
            margin-bottom:0px;
        }

    }

    .mehr-beitraege{
        margin-top:10px;
        position: relative;
        height: 120px;
        border-top: 2px solid $light-gray;
        width: 95%;
        @include breakpoint(medium){
            margin-top:35px;
            width: 96%;
        }
        @include breakpoint(large){
            width: 1025px;
        }
    }

    .mehr-icon{
        @include image-png ("icn_more_hover", 40px, 40px, 40px, 40px);
        position: absolute;
        left: calc(50% - 20px);
        top: 20px;
        z-index:3;

        &:after {            
            content:"";
            @include image-png ("icn_more", 40px, 40px, 40px, 40px);
            position: absolute;
            left: 0%;
            top: 0px;
            transition: all 0.4s ease-in-out;
            z-index: 2;
        }

        &:hover{
            &:after{
                opacity: 0;
            }
        }
    }

    h3{
        a{
            transition: all 0.25s ease-in-out;
        }
    }

    .beitrag-teaser, .beitrag-teaser2 {
        padding-top: 23px;
        padding-bottom: 30px;
        position: relative;

        .leistungen-box{
            $animatedTextChildren: ('.h3', '.h6', 'p', '.text-link');
            $delayStep: 0.1s;

            $delay: 0.2s;
            @each $child in $animatedTextChildren {
                #{$child} {
                    opacity: 0;
                    transform: translateY(15px);
                    transition: transform .5s ease-in-out $delay, opacity .5s ease-in-out $delay;                   
                }
                $delay: $delay + $delayStep;
            }
            &.animate {
                @each $child in $animatedTextChildren {
                    #{$child} {
                        opacity: 1;
                        transform: translateY(0px);
                    }
                }
            }
        }
    }

    .beitrag-teaser {
        background-color:$light-gray;

        @include breakpoint(medium up) {
            margin-top:60px;
        }
    }

    .beitrag-teaser2 {
        @include breakpoint(medium up) {
            margin-top:30px;
        }
    }
}

.text-link{
    transition: 0.5s ease-in-out 0.5s;

    &.animationComplete {
        transition: all 0.25s ease-in-out !important;
    }
}

.fachbeitraege-detail{
    padding-bottom:0px;
    opacity: 0;
    transition: all ease-in-out 1s;

    &.animate{
        opacity: 1;
    }

    .zurueck{
        @include breakpoint(medium down){
            margin-bottom:22px;
        }
    }

    .paragraph-link{
        color: #667f8b;
        transition: all 0.25s ease-in-out;
        border-bottom: 1px solid #667f8b;
        &:hover{
            color: #d2e0e8;
            transition: all 0.25s ease-in-out;
            border-bottom: 1px solid #d2e0e8;
        }
    }

    .h1-beitraege+p,
    .autor+.stern{
        padding-top:20px;
    }
    .autor{
        padding-top:30px;
        padding-bottom:0px;
        /*        padding-bottom:30px;*/
    }

    .stern{
        font-size:14px;
    }

/*    .autor+.stern{
        padding-top:20px;
    }*/

    .header-beitrag{
        background-color: $light-gray;
        position:relative;
        margin-top:90px;
        p{
            padding-top: 30px;
            padding-bottom: 30px;
            margin:0px;
        }
    }
    .image-container{
        max-width:500px;

    }
    .header-img{
        width: 100%;
        margin-top: -60px;
        /*padding-top:30px;*/
    }

    p{
        padding-top:30px;
        padding-bottom:30px;
        margin-top:0;
        margin-bottom:0;
    }

    .p-mobile{
        padding-top:0px;
    }

    .beitrag-detail-container{    
        margin-bottom:30px;
        @include breakpoint(medium up){
            margin-top:60px;
            margin-bottom:30px;
        }
    }

    .subheadline{
        margin-top:0;
        margin-bottom:0;
        padding-top:30px;
        font-size:20px;
    }

    p+.subheadline{
        padding-top: 22px;
    }

    .video-responsiv{
        overflow:hidden;
        padding-bottom:56.25%;
        position:relative;
        height:0;
        iframe{
            left:0;
            top:0;
            height:100%;
            width:100%;
            position:absolute;
        }
    }

    .video-container{
        position:relative;
        text-align:center;
        margin: 0 auto;
        margin-top:30px;
        margin-bottom:30px;
        max-width:500px;

        @include breakpoint(medium down){
            margin-top:0px;
        }

        video{
            width:100%;
            max-width:500px;
            height:auto;
            display:block;
        }
        a, .preview-video{
            display:block;            
            height:100%;
            background-color: $dark-blue;
            width:100%;
            max-width:500px;
            height:auto;
            margin-left:auto;
            margin-right:auto;
            &:hover{
                .video-img{
                    transition: all 0.4s ease-in-out;
                    opacity: 0.75;
                }
            }
        }

        .preview-video{
            position: relative;

            .video-icon{
                @include absolute-center;
                margin: 0;
                pointer-events: none;
                opacity: 0;
            }

            .inactive{
                width: 100%;
                opacity: 0.4;
                transition: all 0.4s ease-in-out;

                + .video-icon{
                    opacity: 1;
                }

                &:hover{
                    cursor: pointer;
                    transition: all 0.4s ease-in-out;
                    opacity: 0.75;
                }
            }
        }

        video{
            opacity: 1;

            &:focus{
                outline: none;
            }
        }
    }

    .video-img{
        width: 500px;
        opacity: 0.4;
        transition: all 0.4s ease-in-out;

    }

    .video-icon{
        @include image-png ("icon_video", 40px, 40px);
        display: block;
        position: absolute;
        top:50%;
        left: 50%;
        margin-left: -20px;
        margin-top: -20px;
    }


    .kontakt-section{
        padding-top: 27px;
        padding-bottom: 44px;
    }

    .zurueck-container{
        position: relative;
        height: 90px;
        margin-top: 60px;
        border-top: 2px solid $light-gray;
        width: 96%;
        @include breakpoint(large){
            width: 1025px;
        }
    }
    .zurueck-icon{
        position: absolute;
        left: 50%;
        margin-left: -20px;
        top: 20px
    }
    .accordion{
        margin-bottom:0px;
        li{
            .content{
                padding-bottom: 20px;
                @include breakpoint(medium){
                    padding-bottom:30px;
                }
                @include breakpoint(large){
                    padding-bottom:40px;
                }
            }

            &:last-child{      
                .content{
                    padding-bottom:0px;

                }
            }
        }

    }
    .accordion-content{        
        .content{
            padding-top: 30px;
            padding-bottom: 0px;
            .last-p{
                padding-bottom:0px;
            }
            .bildbeschreibung{
                padding-top:15px;
                @include breakpoint(medium){
                    padding-top:30px;
                }
            }
            img{
                width:100%;
                @include breakpoint(medium up){
                    padding-bottom:30px;
                }
            }
            .image-container{
                max-width:500px;
            }
        }
        ul{
            margin-bottom:0;
        }
    }
}