.model-origami {
    .model {
        .line-main {
            color: $dark-blue;
            font-family: 'Roboto-Medium';
        }
        .line-sub {
            color: $dark-gray;
            &.light {
                color: $white;
            }
        }
    }
    
    .key {
        @include breakpoint(xmedium up) {
            display: none;
        }

        $width: 300px;
        $icon-offset: 7px;
        $icon-width: 25px;
        $icon-margin: 20px;

        width: $width;
        margin: 50px auto 0;

        .key-item {
            position: relative;
            
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }

            .icon-key {
                position: absolute;
                top: 0;
                left: $icon-offset;
            }
            .key-text {
                width: $width - $icon-offset - $icon-width - $icon-margin;
                margin-left: $icon-offset + $icon-width + $icon-margin;
                text-align: left;
            }
        }
    }
    

    .annotation {
        margin-top: 55px;
    }
}


.arrow-column {
    position: relative;
}

.arrow-border {
    $border-style: 2px solid $dark-gray;
    $line-positions: ('top', 'bottom');
    $arrow-positions: ('left', 'right');
    $margin: 30px;
    $padding: 15px;

    position: absolute;
    width: calc(100% - 2 * 0.9375rem);

    &::after {
        content: '';
        position: absolute;
        background: {
            image: url('../img/arrowhead.svg');
            size: 100% 100%;
            repeat: no-repeat;
            position: top left;
        }
        width: 10px;
        height: 12px;
    }
    
    // top, bottom
    @each $pos in $line-positions {
        &.#{$pos} {
            #{$pos}: 0;
            border-#{$pos}: $border-style;
            padding-#{$pos}: $padding;
            margin-#{$pos}: $margin;

            &::after {
                #{$pos}: -7px;
            }
        }
    }

    &.left {
        text-align: right;
        &::after {
            left: -5px;
            transform: scaleX(-1);
        }
    }

    &.right {
        text-align: left;
        &::after {
            right: -5px;
        }
    }
}