.photo-circle {
    $size: 395px;

    border: 6px solid $lighter-blue;
    border-radius: 50%;

    @each $person in (carsten, marc, melanie) {
        &.#{$person} {
            @include image-png-scalable('berater/circle-#{$person}', $size, $size);
        }
    }
}