

/*
*   BACKGROUNDS
*/



/*
*   BORDERS
*/



/*
*   FONT WEIGHT
*/

strong, bold, .bold {
    @include font-bold;
}



/*
*   HELPER CLASSES
*/

.hidden-content {
    display: none;
}



/* Läuft bis Browserrand */
.wrapper-fullwidth {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: initial;
}

/* Sorgt für gewünschten Abstand zum Browser*/
.wrapper-page {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
    position: relative;
    @include breakpoint(medium up) {
        padding-left: 0px;
        padding-right: 00px;
    }
}


.nopadding {
    padding: 0;
}

/*
*   TOGGLE MENU
*/

.toggle-menu {
    position:relative;

    .action-close {
        display:none;
    }

    &.active {
        .action-open {
            display:none;
        }
        .action-close {
            display:inline-block;;
        }
    }
}

.c-bg-light-gray{
    background-color: $light-gray;
}

.c-bg-medium-blue{
    background-color: $medium-blue;
}

.c-bg-dark-gray{
    background-color: $dark-gray;
}

/* webkit-tap-highlight-color */
* { -webkit-tap-highlight-color: transparent; }

/* To set it to none use a totally transparent RGBA */
//* { -webkit-tap-highlight-color: rgba(0,0,0,0); }

a:focus{
    color: $light-blue;
}