.origami {
    // padding-bottom: 20px;
    
    &.bee {
        @include image-png-scalable('origami/bee', 152px, 242px);
    }

    &.butterfly {
        @include image-png-scalable('origami/butterfly', 322px, 232px);
    }
    
    &.butterfly_circle {
        @include image-png-scalable('origami/butterfly_circle', 322px, 306px);
    }
    
    &.dolphin {
        @include image-png-scalable('origami/dolphin', 342px, 202px);
    }

    &.dragonfly {
        @include image-png-scalable('origami/dragonfly', 321px, 286px);
    }

    &.frog {
        @include image-png-scalable('origami/frog', 320px, 191px);
    }
    
    &.instruction-1 {
        @include image-png-scalable('origami/instruction-1', 322px, 323px);
    }

    &.instruction-2 {
        @include image-png-scalable('origami/instruction-2', 286px, 229px);
    }
    &.instruction-2.normalized {
        @include image-png-scalable('origami/instruction-2-normalized', 322px, 323px);
    }

    &.instruction-3 {
        @include image-png-scalable('origami/instruction-3', 213px, 320px);
    }
    &.instruction-3.normalized {
        @include image-png-scalable('origami/instruction-3-normalized', 322px, 323px);
    }

    &.lion {
        @include image-png-scalable('origami/lion', 322px, 184px);
    }

    &.model {
        @each $model in (engagement, learning, strategy) {
            &.#{$model} {
                @include image-png-scalable('origami/model-#{$model}-mobile', 300px, 300px);
                @include breakpoint(xmedium up) {
                    @include image-png-scalable('origami/model-#{$model}', 320px, 320px);
                }
            }
        }
    }

    &.parrot {
        @include image-png-scalable('origami/parrot', 225px, 262px);
    }

    &.rabbit {
        @include image-png-scalable('origami/rabbit', 152px, 177px);
    }
    &.owl {
        @include image-png-scalable('origami/owl', 205px, 262px);
    }
    &.bull {
        @include image-png-scalable('origami/bull', 322px, 191px);
    }
}


.origami-instruction-loop {
    max-width: 50%;
    padding-bottom: 50%;
    margin: 30px auto 60px auto;

    @include breakpoint(medium up) {
        max-width: 323px;
        padding-bottom: 100%;
        margin: 0 auto;
    }
}
