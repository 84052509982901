.nav-main{
    ul{
        &.menu{
            display: inline-block;
            float: right;
            li {
                display: inline-block;
                a{
                    text-transform: uppercase;
                    font-family: 'Roboto-Medium';
                    font-size: 12px;
                    color: $dark-blue;
                    letter-spacing: 0.1em;
                    transition: all 0.25s ease-in-out;

                    &:hover{
                        color: $medium-blue;
                    }

                    &.active {
                        position: relative;
                        color: $medium-blue;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: calc(100% - 2rem);
                            left: 1rem;
                            bottom: 0;
                            border-bottom: 2px solid $medium-blue;
                        }
                    }
                }
            }
        }
    }
}


.nav-bar{
    &.wrapper-fullwidth{
        .row:first-child{
            padding-top: 10px;
        }
    }

    .hamburger{
        z-index: 501;
        &.is-active{
            position: fixed;
            top:10px;
            right: 10px;
        }
    }
    .hamburger-box{
        width: 20px;
        height: 14px;
    }
    .hamburger-inner:after,
    .hamburger-inner:before,
    .hamburger-inner{
        background-color: $dark-blue;
        width: 20px;
        height: 2px;
        border-radius: 0px;
    }

    .hamburger-inner:before{
        top:-6px;
    }
    .hamburger-inner:after{
        bottom:-6px;
    }
}
.on-fullscreen-navi{
    z-index: 501;
}
.fullscreen-navi{
    position: fixed;
    top: 0;
    right: -100%;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: $medium-blue;
    z-index: 500;
    transition: all 0.4s ease-in-out;
    filter: blur(40px);

    &.active{
        right: 0%;
        opacity: 1;
        filter: blur(0px);
    }

    .row{
        height: 100%;
    }
    ul{
        width: 100%;
        text-align: center;
        margin: 0px;
        li{
            width:100%;
            text-align: center;
            display: inline-block;
            a{
                font-size: 24px;
                line-height: 60px;
                letter-spacing: 0.2 rem;
                color: $dark-blue;
                text-transform: uppercase;
                font-family: 'Roboto-Medium';
                transition: all 0.25s ease-in-out;
                &:hover{
                    color: $light-blue;
                }

                &.active {
                    position: relative;
                    color: $light-blue;

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        left: 0;
                        bottom: -10px;
                        border-bottom: 2px solid $light-blue;
                    }
                }
            }
        }
    }
}