@keyframes animateInOverlay {
    0% {
        opacity:0;
        filter: blur(15px);
    }
    100% {
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes animateOutOverlay {
    0% {
        filter: blur(0px);
        //transform: translateX(0%);
        opacity: 1;
    }
    100% {
        //transform: translateX(-100%);
        opacity:0;
        filter: blur(15px);
    }
}

.content.subpage {
    &.overlay,
    &.overlay-imprint{
        overflow: auto;
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: $white;
        z-index: 100;
        padding-top: 0px;
        padding-bottom: 0px;

        display: none;
        opacity: 0;

        section.header {
            position: relative;
            .logo{
                @include breakpoint(small down) {
                    width: 48px;
                    height: 50px;
                    background-size: auto 100%;
                }
            }
            margin-bottom: 110px;
        }
        .overlay-headline {
            margin: auto;
            text-align: center;
            letter-spacing: 0.1em;
            color: $medium-blue;
            font-family: $body-font-family;

            @include breakpoint(small down) {
                font-size: 1.8rem;
            }

        }

        .close-overlay-wrapper{
            position: absolute;
            right:2%;
            top: 50%;

            margin-top: -20px;
        }
        h2{
            letter-spacing: 0.2em;
        }
        p{
            letter-spacing: 0.05em;
        }

        &.animateIn{
            display: block;
            animation: animateInOverlay 0.75s ease-in-out forwards;
            &.animateOut{
                animation: animateOutOverlay 0.75s ease-in-out forwards;
            }
        }
    }
}