.brace {
    display: block;
    width: 65px;
    // height should be set inline
    
    // margin-right: 30px;
    // @include breakpoint(medium down) {
    //     margin-right: 20px;
    // }

    float: left;

    background: {
        image: url(../img/brace.svg);
        repeat: no-repeat;
        position: top center;
        size: 100% 100%;
    }

    & + .enumeration {
        margin-left: 95px;
        @include breakpoint(medium down) {
            margin-left: 85px
        }
    }

    &.flip {
        transform: scaleX(-1);
    }
}