
.cc-window{
    bottom: 0;
}

.cc-revoke, .cc-window{
    font-family: 'Roboto-Regular';
    
    @include breakpoint(medium down) {
        font-size: 14px;
    }
}

.cc-window.cc-floating{
    max-width: none;
    width: 100%;
}

.cc-btn{
    text-transform: uppercase;
    border-radius: 0;
    display: inline-block;
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 0.1em;
}

.cc-link{
    display: none;
}

.cc-message{
    padding-right: 20px;
}

.cc-banner .cc-message {
    -ms-flex: 1;
    flex: 1;
}

.cc-window.cc-floating {
    @include breakpoint(medium down) {
    padding: 1rem;
    }
}

.cc-compliance>.cc-btn{
   flex: none;
}