
.slider-wrapper{
    margin: auto;
    position: relative;
}

.slider-referenzen-wrapper {
    .slider-referenzen {
        transform: translate(0px, 20px);
        transition: all .25s ease-in-out 1s;
        opacity: 0;
        height: max-content;
        .slide-ref-content {
            &.h3{
                text-transform: uppercase;
            }
        }

        #slide-ref-img-1 {
            @include image-png ("referenzen/slide-ref-1", 152px, 110px, 152px, 110px);
            background-position: center center;
            width: 100%;
            height: 110px;
            display: inline-block;
            margin: 10px auto 0px;
        }

        #slide-ref-img-2 {
            @include image-png ("referenzen/slide-ref-2", 133px, 144px, 133px, 144px);
            background-position: center center;
            width: 100%;
            height: 144px;
            display: inline-block;
            margin: 10px auto 0px;
        }
        
        #slide-ref-img-3 {
            @include image-png ("referenzen/slide-ref-3", 150px, 92px, 150px, 92px);
            background-position: center center;
            width: 100%;
            height: 93px;
            display: inline-block;
            margin: 10px auto 0px;
        }

         #slide-ref-img-4 {
            @include image-png ("referenzen/slide-ref-4", 160px, 96px, 160px, 96px);
            background-position: center center;
            width: 100%;
            height: 96px;
            display: inline-block;
            margin: 10px auto 0px;
        }
        
        .slide-ref-logo {
            min-width: 150px;
            min-height: 120px;
            list-style: none;
            display: inline-block;
            position: relative;
            cursor: pointer;
            width: 100%;
            margin: auto;
            @include breakpoint(medium up) {
                display: none;
            }
            &.logo-1:after {
                @include image-png ("referenzen/referenz_cronimet", 100px, 100px, 100px, 200px);
                background-position: center 0px;
            }
            &.logo-2:after {
                @include image-png ("referenzen/referenz_eurofins", 100px, 100px, 100px, 200px);
                background-position: center 0px;
            }
            &.logo-3:after {
                @include image-png ("referenzen/referenz_valovisbank", 100px, 100px, 100px, 200px);
                background-position: center 0px;
            }
            &.logo-4:after {
                @include image-png ("referenzen/referenz_stonehage", 100px, 100px, 100px, 200px);
                background-position: center 0px;
            }
            &:after {
                content: ' ';
                font-size: 0px;
                height: 100px;
                width: 100px;
                display: inline-block;
                position: absolute;
                top: 50%;
                margin-top: -50px;
                left: 50%;
                margin-left: -50px;

            }

        }

        .slide-ref-desc {
            padding-top: 25px;
            text-transform: uppercase;
        }

        .slick-dots {
            display: inline-block;
            text-align: center;
            width: 100%;
            margin: 10px 0 0 0;
            li {
                border-radius: 20px;
                width: 20px;
                height: 20px;
                margin: 10px;
                background-color: $light-blue;
                list-style: none;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                transition: all 0.25s ease-in-out;
                &:hover, &:focus, &.slick-active {
                    background-color: $dark-blue;
                }
                button {
                    font-size: 0;
                    display: none;
                }
                ;
            }
        }
    }
    .slider-arrows {
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        .left-arrow{
            float: left;
        }
        .right-arrow{
            float: right;
        }
        @include breakpoint(medium up) {
            display: none;
        }
        @include breakpoint(medium down) {
            .left, .right{
                &:hover, &:active{
                    border: 2px solid $light-blue;
                    &:after{
                        border-top: 2px solid $light-blue;
                        border-right: 2px solid $light-blue;
                    }
                }
            }
        }
    }

    .slick-list.draggable{
        /*        max-height: 385px;*/
        @include breakpoint(medium down) {
            max-height:inherit;
        }
    }
    .slick-slide{
        height: inherit;
    }
}

.slider-referenzen-navi{
    position: absolute;
    top:0px;
    right:0px;
    z-index: 100;
    transition: all 0.25s ease-in-out 1.25s;
    transform: translate(70px, 0px);
    opacity: 0;
    ul{
        margin: 0px;
    }
    .slick-ref-navi-links{
        min-width: 150px;
        min-height: 150px;
        list-style: none;
        border-bottom: solid 2px $light-blue;
        display: inline-block;
        position: relative;
        cursor: pointer;

        &#slide-1 {
            span{
                @include image-png ("referenzen/referenz_cronimet", 100px, 100px, 100px, 200px);
            }
            &.active span,span:after{
                @include image-png ("referenzen/referenz_cronimet", 100px, 100px, 100px, 200px);
                background-position: center -100px;
            }
        }
        &#slide-2 {
            span{
                @include image-png ("referenzen/referenz_eurofins", 100px, 100px, 100px, 200px);
            }
            &.active span, span:after{
                @include image-png ("referenzen/referenz_eurofins", 100px, 100px, 100px, 200px);
                background-position: center -100px;
            }
        }
        &#slide-3 {
            span{
                @include image-png ("referenzen/referenz_valovisbank", 100px, 100px, 100px, 200px);
            }
            &.active span, span:after{
                @include image-png ("referenzen/referenz_valovisbank", 100px, 100px, 100px, 200px);
                background-position: center -100px;
            }
        }
         &#slide-4 {
            span{
                @include image-png ("referenzen/referenz_stonehage", 100px, 100px, 100px, 200px);
            }
            &.active span, span:after{
                @include image-png ("referenzen/referenz_stonehage", 100px, 100px, 100px, 200px);
                background-position: center -100px;
            }
        }
        span {
            font-size: 0px;
            height: 100px;
            width: 100%;
            display: inline-block;
            position: absolute;
            top: 50%;
            margin-top: -50px;
            left:50%;
            margin-left: -50px;
            &:after{
                transition: all 0.25s ease-in-out;
                opacity: 0;
                content: '';
                left:0px;
                width: 100%;
                height: 100%;
                display: inline-block;
                position: absolute;
                top: 0px;
                z-index: 10;
            }
        }

        &:hover{
            span:after {

                opacity: 1;
            }
        }
    }

    .slick-current{
        .slick-ref-navi-links{
            &#slide-1 {
                span{
                    background-position: center -100px;
                }
            }
            &#slide-2 {
                span{
                    background-position: center -100px;
                }
            }
            &#slide-3 {
                span{
                    background-position: center -100px;
                }
            }
            &#slide-4 {
                span{
                    background-position: center -100px;
                }
            }
        }
    }

    .slick-slide{
        float: inherit;
    }

    .slick-track{
        width: auto !important;
        transform: translate3d(0px, 0px, 0px) !important;
    }

}



/*.slider-berater-wrapper{

    transition: all 0.25s ease-in-out 1.25s;
    transform: translate(0px, 20px);
    opacity: 0;

    .slider-arrows {
        top: 80px;
    }

    .slick-dots {
        top: 60px;
    }

    .slide{
        .left{
            h6{
                margin-bottom: 0px;
            }
            .h3{
                line-height: 28px;
                padding-bottom: 15px;
                text-transform: uppercase;
                font-size: 18px;

            }
            p{
                &.headerlin{
                    font-family: 'Roboto-Medium';
                    font-weight: bold;
                    color:$medium-blue;
                    margin-bottom: 7px;
                }
                margin-bottom: 0px;
                line-height: 24px;
            }
            .skill-box{
                padding-top: 45px;
            }

        }
        .right{
            position: relative;
            min-height: 600px;
            #slide-ber-img-1{
                background: url(../img/berater/berater-slide-1.png) no-repeat right bottom;
                width: 631px;
                height: 503px;
                position: absolute;
                //bottom: 0px;
                right: 12px;
                display: inline-block;
            }
            #slide-ber-img-2{
                background: url(../img/berater/berater-slide-2.png) no-repeat right bottom;
                width: 631px;
                height: 503px;
                position: absolute;
                //bottom: 0px;
                right: 12px;
                display: inline-block;
            }

            div[id^="slide-ber-img-"]{
                bottom: -20px;
                opacity: 0;
                transition: all 0.25s ease-in-out 0.45s;
            }

            @include breakpoint(medium down) {
                min-height: 340px;
                #slide-ber-img-1, #slide-ber-img-2{
                    background-size: 100%;
                    width: 95%;
                    height:100%;
                }
            }
            @include breakpoint(small down) {
                #slide-ber-img-1, #slide-ber-img-2{
                    background-size: auto 80% ;
                    background-position: center bottom;
                }
            }
        }
        .text-link{
            margin-bottom: 15px;
        }
        .row{
            height: 100%;
        }
    }
    .slick-slide{
        height: inherit;
    }
    .skill-box{
        opacity: 0;
        position: relative;
        top:-5px;
        &:nth-child(1){transition: all 0.25s ease-in-out 0.45s}
        &:nth-child(2){transition: all 0.25s ease-in-out 0.6s}
        &:nth-child(3){transition: all 0.25s ease-in-out 0.75s;}

        .text-link{
            margin-bottom: 60px;
        }
    }

}*/

.animate{

    .slick-active{
        .skill-box{
            opacity: 1;
            top:0px;
        }
        div[id^="slide-ber-img-"]{
            bottom: 0px !important;
            opacity: 1 !important;
        }
    }

    // Referenz slider
    &.referenzen{
        .slider-referenzen{
            transform: translate(0px, 0px);
            opacity: 1;
        }
        .slider-referenzen-navi{
            transform: translate(0px, 0px);
            opacity: 1;
        }
    }

/*    .slider-berater-wrapper{
        transform: translate(0px, 0px);
        opacity: 1;
    }*/

}

.slider-leistungen-personal-wrapper {
    // Fix for adaptive height
    .slick-slide {
        height: auto;
    }

    .slider-arrows {
        top: 63px;
    }

    .slick-dots {
        top: 43px;
    }

    .slide {
        .h3, .h4 {
            line-height: 28px;
            padding-bottom: 0px;
            text-transform: uppercase;
        }
    }

    @include breakpoint(xmedium down) {
        .slider-arrows {
            top: 91px;
        }
        .slick-dots {
            top: 71px;
        }
        .slide-headline-container {
            position: relative;
            height: 71px;

            .slide-header {
                position: absolute;
                bottom: 0;
            }
        }

        .h3 {
            margin-bottom: 0;
        }
    }

    @include breakpoint(medium down) {
        .slider-arrows {
            top: 120px;
        }
        .slick-dots {
            top: 100px;
        }
        .slide-headline-container {
            height: 100px;

            // .slide-header {
            //     position: absolute;
            //     bottom: 0;
            // }
        }

        // .h3 {
        //     margin-bottom: 0;
        // }
    }
}

.slider-top-arrows {
    position: relative;

    .slider-arrows-container {
        position: relative;
    }

    .slider-arrows {
        z-index: 1000;
        position: absolute;
        width: 100%;
        text-align: center;
        a{
            padding: 0px 5px 0px;
        }
    }
}

.slide-indicator-line {
    .slick-dots{
        position: absolute;
        margin: 0px;
        text-align: center;

        display:flex;
        width:100%;
        li{
            display: inline-block;
            text-align: center;
            flex: 1 1 auto;
            font-size: 0px;
            height: 2px;
            background-color: $light-blue;
            transition: background-color 0.25s ease-in-out;
            &.slick-active{
                background-color: $dark-blue;
            }
        }

        @include breakpoint(medium up) {
            &.dots-10-in-12 {
                width: calc(83.33333% - 0.46875rem);
                margin-left: 8.66666%;
            }
        }
    }
}

.slide-content {
    margin-top: 100px;
    margin-bottom: 40px;

    &.bullet-origami {
        .origami {
            margin-bottom: 30px;
            @include breakpoint(large up) {
                margin-bottom: 0;
            }
        }
    }
}

.slick-slider {
    .slider-content-blur-fade-in {
        transition: all .25s ease-in-out 0.5s;
        filter: blur(15px);
        opacity: 0;
    }
    .slider-content-move-in {
        transition: all .25s ease-in-out 0.75s;
        transform: translateY(-15px);
        opacity: 0;
    }
    .slider-image-rotate-in {
        transition: all .25s ease-in-out 0.75s;
        transform: rotateY(90deg) rotateX(90deg);
        opacity: 0;
    }

    .slick-active{
        .slider-content-blur-fade-in {
            filter: blur(0px);
            opacity: 1;
        }
        .slider-content-move-in {
            transform: translateY(0px);
            opacity: 1;
        }
        .slider-image-rotate-in {
            transform: rotateY(0deg) rotateX(0deg);
            transform-style: preserve-3d;
            perspective: 1500;
            opacity: 1;
        }
    }
}

@include breakpoint(small down) {
    .slider-referenzen-navi{
        display: none;
    }
}
