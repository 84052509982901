.site-footer {

    @include breakpoint(medium down) {
        .sm-order-1{
            order: 1;
            margin-bottom: 50px;
        }
        .sm-order-2{
            order: 2;
        }
        .sm-order-3{
            order: 3;
        }
        .small-text-align{
            text-align: center;
        }
    }


    .footer-logo {
        @include image-png ("footer_logo", 93px, 99px);
        display: block;
        @include breakpoint(medium down) {
            margin: auto;
        }
    }

    .bottom-content-section {
        position: absolute;
        left: 50%;
        margin-left: -20px;
        top: 85px;
        .title {
            top: -30px;
        }
        @include breakpoint(medium down) {
            position: relative;
            width: 100%;
            left:inherit;
            margin: 40px auto 40px auto;
            top:inherit;
            text-align: center;
        }
    }
    .footer-content-middle{
        margin: 100px auto 100px;
    }
    .footer-navi{
        ul {
            margin: 0px;
            li{
                text-align: right;
                display: inline-block;
                width: 100%;
                margin-bottom: 10px;
                a{
                    color: $white;
                    font-family: 'Roboto-Regular';
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 0.1em;
                    transition: color 0.25s ease-in-out;
                    &:hover{
                        color: $medium-blue;
                    }
                }
            }
        }
        @include breakpoint(medium down) {
            ul {
                margin-top:30px;
                li{
                    text-align: center;
                }
            }
        }
    }
    @include breakpoint(medium down) {
        .footer-icons {
            margin-bottom: 40px;
        }
    }

    .icn-mail{
        display: inline-block;
        @include image-png ("icn_mail", 48px, 48px, 48px, 98px);
        position: relative;
        &:after{
            content:' ';
            position: absolute;
            top:0px;
            left:0px;
            opacity: 0;
            width: 100%;
            height: 100%;
            @include image-png ("icn_mail", 48px, 48px, 48px, 98px);
            background-position: 0px -49px;
            transition: all 0.25s ease-in-out;
        }
        &:hover{
            &:after{
                opacity: 1;

            }
        }
    }
    .icn-phone{
        display: inline-block;
        @include image-png ("icn_phone", 48px, 48px, 48px, 98px);
        position: relative;
        &:after{
            content:' ';
            position: absolute;
            top:0px;
            left:0px;
            opacity: 0;
            width: 100%;
            height: 100%;
            @include image-png ("icn_phone", 48px, 48px, 48px, 98px);
            background-position: 0px -49px;
            transition: all 0.25s ease-in-out;
        }
        &:hover{
            &:after{
                opacity: 1;
            }
        }
    }

    .copyright, .imprint{
        color: $white;
        font-family: 'Roboto-Regular';
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.1em;
        transition: color 0.25s ease-in-out;
    }

    .imprint{
        margin-left: 30px;
    }

    .imprint:hover{
        color: $medium-blue;
    }
    @include breakpoint(medium down) {
        .footer-content-middle{
            margin: 50px auto 50px auto;
        }
    }

    .footer-netzwerk{
        margin-bottom: 60px;
        padding: 20px 0 0 0;
        border-top: 2px solid $medium-gray;
        border-bottom: 2px solid $medium-gray;
        color: $white;
        text-align: center;
        .netzwerk-logo-link{
            position: relative;
            width: 100%;
            display: block;
            max-height: 160px;
            &:before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            .netzwerk-logo{
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                &:hover{
                    background-position: 0 -160px;
                }
                &.logo-krass{
                    @include image-png("netzwerk/netzwerk_krass", 160px, 160px);
                }
                &.logo-liebeindiewelt{
                    @include image-png("netzwerk/netzwerk_liebeindiewelt", 160px, 160px);
                }
                &.logo-mokom01{
                    @include image-png("netzwerk/netzwerk_mokom01", 160px, 160px);
                }
            }
        }
    }
}
