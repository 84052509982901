
form {
    
    input[type="text"], textarea, select
    {
        appearance: none;
        border: {
            top: {
                style: none;
                width: 0;
            }
            right: {
                style: none;
                width: 0;
            }
            bottom: {
                style: solid;
                width: 2px;
                color: $medium-blue;
            }
            left: {
                style: none;
                width: 0;
            }
        }
        box-shadow: none;
        color: $dark-gray;
        margin: 0 0 rem-calc(10);
        padding: {
            left: rem-calc(30);
            right: rem-calc(30);
        }

        @include breakpoint(small down) {
            padding: {
                left: 0;
                right: 0;
            }
        }


        &:focus {
            border-bottom: {
                style: solid;
                width: 2px;
                color: $dark-blue;
            }
        }
    }
    textarea{
        padding: 5px 30px 5px 30px;
        line-height: 22px;
        height: 78px!important;
        @include breakpoint(small down) {
            padding:16px 0px 0 0px;
        }
    }
    input::placeholder, textarea::placeholder{
        color: $dark-gray;
        opacity: 1;
    }

    input::-webkit-input-placeholder{
        color: $dark-gray;
        opacity: 1;
    }
    input::-moz-placeholder{
        color: $dark-gray;
        opacity: 1;
    }
    input:-ms-input-placeholder{
        color: $dark-gray;
        opacity: 1;
    }
    input:-moz-placeholder {
        color: $dark-gray;
        opacity: 1;
    }
    
    input[type="text"], textarea {
        @include placeholder {
            
        }
    }
    
    input[type="text"], select{
        height: 34px;
        line-height: 20px;
    }
    
    input[type="text"] {
        
        &.error {
            border-bottom: solid 2px #b02020;
        }
    }
    
    textarea {
        resize: none;

        &.error {
            
        }
    }
    
    select {
        
        option {
            
        }
        
        &.error {
            
        }
    }
    
    label {
        display: inline-block;
        line-height: $input-height;
        color: $dark-gray;
        letter-spacing: 0.05em;
        font-size: 16px;

        &.invisible {
            display: block;
            position: absolute;
            width: 0px;
            height: 0px;
        }

    }

    .phone-row {
        display: none;
        padding-top: 20px;
    }
    
    .radio-wrapper, .checkbox-wrapper{
        label{
            padding-left: 5px;
            padding-right: 30px;

            @include breakpoint(small down) {
                padding-left: 5px;
                &:last-child {
                    padding-right: 0px;
                }
            }
        }

    }
    
    /*
    *   CHOSEN
    */
    
    
        
    /*
    *   ICHECK
    */
    
    .icheckbox {
        @include image-png('checkbox', 20px, 20px, 20px, 100px);
    }
    
    .iradio {
        @include image-png('radio', 20px, 20px, 20px, 100px);
    }
    
    .icheckbox , .iradio {
        cursor: pointer;
        top:4px;
        &.hover {
            background-position: 0 -20px;
        }
        
        &.checked {
            background-position: 0 -40px;
        }
        
        &.checked.hover {
            background-position: 0 -60px;
        }
        
        &.error {
            background-position: 0 -80px;
            &.checked {
                background-position: 0 -40px;
            }
        }
    }
}

.contact-overlay{

}