.columns.image-only {
    img , .origami {
        @include breakpoint(medium down) {
            margin-bottom: 25px;
        }
    }
}

.quick-contact{
    margin-top: 50px;
    @include breakpoint(medium down) {
        margin-top: 30px;
    }
}