
/*
*   COLORS
*/

$brand-primary: #000;
$brand-secondary: #000;
$black: #000;
$white: #fff;



/*
*   FONTS
*/

$font-reg: 'font-family-reg';
$font-bold: 'font-family-bold';
$font-light: 'font-family-light';
$fallback-fonts: Arial, Helvetica, sans-serif;



/*
*   FORM
*/

$input-height: 35px;
$input-border: 1px solid #000;



/*
*   PATHS
*/

$image-path: "../img";
$retina-image-path: "../img/2x";