.accordion-content{
    .content{
        &.c-bg-light-gray{
            @include breakpoint(medium up) {
                padding-top: 50px!important;
            }
        }
    }
}

.accordion-title {
    .h3 {
        margin-bottom: 0;
    }
}

.accordion-title-box {
    position: relative;

    border-bottom: 2px solid $light-blue;
    padding-bottom: 10px;
    padding-right: 29px;

    &::before {
        content: '';
        position: absolute;
        bottom: 24px;
        right: 0;
        transition: transform 0.3s linear;
        @include image-png('accordion-arrow', 19px, 10px);
    }
}

.accordion-header-photo {
    position: relative;

    .photo-container {
        position: relative;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        text-align: center;
        opacity: 0;
        transform: translate(0px, 20px);
        transition: all 1s ease-in-out;

        &.animate{
            opacity: 1;
            transform: translate(0px, 0px);
        }

        @include breakpoint(medium up) {
            height: 170px;
        }
    }


    &.left,
    &.right {
        .photo-circle {
            @include breakpoint(medium up) {
                position: absolute;
                bottom: -40%;
                margin-bottom: -10%;
            }
        }
    }

    &.left {
        .photo-circle {
            @include breakpoint(medium up) {
                left: 0;
            }
            @include breakpoint(large up) {
                right: auto;
                left: calc(50% - #{(395px / 2)});
            }
        }
    }
    &.right {
        .photo-circle {
            @include breakpoint(medium up) {
                right: 0;
            }
            @include breakpoint(large up) {
                right: auto;
                left: calc(50% - #{(395px / 2)});
            }
        }
    }
}

.accordion-item:nth-child(2){
    .accordion-header-photo {
        .photo-container {
            transition: all 1s ease-in-out 0.75s;
        }
    }
}

.accordion-item {
    &.is-active {
        .accordion-title-box {
            &::before {
                transform: rotate3d(1,0,0,180deg);
            }
        }
    }
}

.accordion-berater {
    .accordion-item {
        margin-bottom: 40px;
        @include breakpoint(medium up) {
            margin-bottom: 240px;
        }
    }


    .accordion-title {
        padding-top: 0;
    }
    .accordion-title-box {
        .h6 {
            margin-bottom: 0;
        }
        h3 {

        }
    }


}


#content-persoenlichkeit-methode{
    &.content{
        padding-top: 40px;
        @include breakpoint(medium up) {
            padding-top: 50px;
        }
    }
}
