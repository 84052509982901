
/*
*   FONTS
*/

@mixin font-reg {
    font-family: $font-reg, $fallback-fonts;
}

@mixin font-bold {
    font-family: $font-bold, $fallback-fonts;
}

@mixin font-light {
    font-family: $font-light, $fallback-fonts;
}



/*
*   IMAGES, RETINA IMAGES
*/

// Mixin für PNG-Bild/Icon mit Retinadarstellung
// Parameter Bildpfad, Breite und Höhe (vom Bildausschnitt); optional Breite und Höhe von der gesamten Grafik
// Beispiel: @include image-png('logo', 140px, 40px);
@mixin image-png ($image, $width, $height, $retinaWidth: 100%, $retinaHeight: auto) {
    background-image: url("#{$image-path}/#{$image}.png");
    background-repeat: no-repeat;
    width: $width;
    height: $height;
    display: inline-block;
    @include retina-image-png($image, $retinaWidth, $retinaHeight);
}

// Mixin für PNG-Bild/Icon mit Retinadarstellung
// Behält das Seitenverhältnis des Bildes bei, auch wenn der Container schmaler wird als die Bildgröße
// Beispiel: @include image-png-scalable('logo', 140px, 40px)
@mixin image-png-scalable ($image, $width, $height) {
    max-width: 100%;
    background-image: url("#{$image-path}/#{$image}.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: $width;
    display: inline-block;
    @include retina-image-png($image, 100%, auto);

    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: ($height / $width) * 100%;
    }
}

// Mixin für JPG-Bild/Icon mit Retinadarstellung
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include image-jpg('logo', 140px, 40px);
@mixin image-jpg ($image, $width, $height) {
    background-image: url("#{$image-path}/#{$image}.jpg");
    background-repeat: no-repeat;
    width: $width;
    height: $height;
    display: inline-block;
    @include retina-image-jpg($image, 100%, auto);
}

// Mixin für Retinadarstellung für PNG-Bild/Icon
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include retina-image-png('logo', 140px, 40px);
@mixin retina-image-png($image, $width, $height) {
    @include breakpoint(retina) {
        background-image :url("#{$retina-image-path}/#{$image}_2x.png");
        background-size: $width $height;
        background-repeat: no-repeat;
    }
}

// Mixin für Retinadarstellung für JPG-Bild/Icon
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include retina-image-jpg('logo', 140px, 40px);
@mixin retina-image-jpg ($image, $width, $height) {
    @include breakpoint(retina) {
        background-image: url("#{$retina-image-path}/#{$image}_2x.jpg");
        background-size: $width $height;
        background-repeat: no-repeat;
    }
}


/*
*   PLACEHOLDER
*/

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}


/*
*   ASPECT RATIO
*/
@mixin aspect-ratio($width, $height) {

}