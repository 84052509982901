.button{
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 0.1em;
    &.dark-blue{
        background-color: $dark-blue;
    }
    &.white{
        background-color: $white;
        color: $dark-gray;
    }
    &.medium{
        padding: 20px 30px;
    }
    &:hover, &:focus{
        background-color: $medium-blue;
    }

    &.animationComplete{
        transition: all 0.25s ease-in-out !important;
    }
}