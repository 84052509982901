/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;//regular
    src: url('../fonts/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: 500;//medium
    src: url('../fonts/roboto-v18-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/roboto-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v18-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v18-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
