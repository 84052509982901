
.imprint-overlay {
    .datenschutz{
        margin: 120px 0;
        .h3, h4, h6{
            text-transform: uppercase;
            color: $dark-blue;
        }
        a{
            color: $dark-blue;
            &:hover{
                color: $light-blue;
            }
        }
    }
}