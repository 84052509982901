
$content-fullscreen-text-color: $medium-blue;

@keyframes animateOnigamiTorso {
    0%{
        opacity: 0;
        bottom: -160px;
    }
    80%{
        bottom:-10px;
        transform: rotate(-5deg);
        opacity: 1;
        filter: blur(0px);
    }
    100%{
        bottom:-30px;
        opacity: 1;
        transform: rotate(0deg);
        filter: blur(0px);
    }
}

@keyframes animateOnigamiHead {
    30%{
        transform: rotate(5deg);
        left:5px;
    }
    50%{
        transform: rotate(10deg);
        left:10px;
    }
    100%{
        transform: rotate(0deg);
        left:0px;
    }
}

@keyframes animateOnigamiFlying {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(8px, 5px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}
@include breakpoint(medium down) {
    @keyframes animateOnigamiTorso {
        0%{
            opacity: 0;
            bottom: -160px;
        }
        80%{
            bottom:-120px;
            transform: rotate(-5deg);
            opacity: 1;
            filter: blur(0px);
        }
        100%{
            bottom:-135px;
            opacity: 1;
            transform: rotate(0deg);
            filter: blur(0px);
        }
    }
}

.fullscreen {
    //height: 100vh;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .top-content-section {
        width: 100%;
    }

    .middle-content-section {
        height: 100vh;
        width: 100%;
        min-height: 100%;
        text-align: center;
        position: relative;
        color: $content-fullscreen-text-color;
        p{
            padding-top: 20px;
            padding-bottom: 20px;

            @include breakpoint(medium down) {
                opacity: 1;
            }
        }

    }

    .bottom-content-section {
        padding: 1rem;
        position: absolute;
        bottom: 40px;
        left: 50%;
        margin-left: -50px;
        width: 100px;
        text-align: center;
        @include breakpoint(large down) {
            bottom:15px;
        }

    }

    .animation-content-blur-start {
        position: relative;
        width: 100%;

        & > div {
            position: absolute;
            top: 0px;
            width: 100%;
            &.last{
                position: relative;
            }
        }
    }

    .onigramm{
        position: absolute;
        bottom:-160px;
        right: -50px;
        width: 320px;
        height: 328px;
        opacity: 0;

        //transition: all 3s ease-in-out;
        filter: blur(10px);
        transform: rotate(50deg);

        .onig-head{
            background: url('../img/2x/origami_bird_part3_2x.png') no-repeat;
            background-size: 100%;
            width:119px;
            height: 108px;
            position: absolute;
            top:36px;
            z-index: 1;

            left: 44px;
            transform: rotate(45deg);

        }

        .onig-torse{
            background: url('../img/2x/origami_bird_part2_2x.png') no-repeat;
            background-size: 100%;
            width:245px;
            height: 288px;
            position: absolute;
            top:0px;
            right: 0px;
            z-index: 2;

        }

        .onig-bottom{
            background: url('../img/2x/origami_bird_part1_2x.png') no-repeat;
            background-size: 100%;
            width:77px;
            height: 180px;
            position: absolute;
            top: 144px;
            //right: 52px; default
            z-index: 3;

            transition: all 1.5s ease-in-out 2s;
            right: 28px;
            transform: rotate(-15deg);
        }

        &.animate{

            animation:  animateOnigamiTorso 3s ease-in-out forwards,
                        animateOnigamiFlying 3s ease-in-out infinite 3s;

            .onig-head{
                animation: animateOnigamiHead 3s ease-in-out forwards 1.5s;
            }

            .onig-bottom{
                right: 52px;
                transform: rotate(0deg);
            }
        }


        @include breakpoint(medium down) {
            right: -90px;
            bottom:-135px;
        }
    }

    .fulls-c-1, .fulls-c-2{
        max-width: 750px;
        width: 100%;
        margin: auto;
        .animation-copy{
            max-width: 490px;
            width: 100%;
            margin: auto;
        }
    }


    .animation-content-blur-2{
        @include breakpoint(medium down) {
            opacity: 0;
        }
        @include breakpoint(small down) {
            opacity: 1;
        }
    }

    .animation-content-blur-3{
        @include breakpoint(medium down) {
            opacity: 0;
        }
        @include breakpoint(small down) {
            opacity: 1;
        }
    }

    @include breakpoint(small down) {
        height: 100%;
        min-height: 780px;

        .align-self-middle{
            align-self: inherit;
            padding-top: 30px;
        }

        .fulls-c-1, .fulls-c-2{
            width: 100%;
            float: left;
            .animation-copy{
                width: 100%;
                margin: auto;
            }
        }
        .onigramm{
            display: none;
            width: 160px;
            height: 164px;

            bottom:-80px;
            right: -25px;

            .onig-head{
                width: 59.5px;
                height: 54px;

                top: 18px;
                left: 22px;
            }

            .onig-torse{
                width:122.5px;
                height: 144px;
            }

            .onig-bottom{
                width:38.5px;
                height: 90px;
                top: 72px;
                right: 14px;
            }

            &.animate{
                .onig-bottom{
                    right: 26px;
                    transform: rotate(0deg);
                }
            }

        }

        .full_origami_mobile{
/*            width: 280px;
            height: 250px;
            background: url(../img/origami/full_origami_mobile.png) no-repeat 0px 0px;*/
            @include image-png ("origami/full_origami_mobile", 280px, 250px);
            background-size: 100%;
            margin: 0px auto 40px auto;
            clear: both;
        }

        .bottom-content-section{
            position: relative;
            margin-left: -50px;
            left: 50%;
            top: inherit;
            bottom: inherit;
            width: 100px;
        }
        .middle-content-section{
            height: 100%;
        }

        .row{
            display: block;
        }
    }
}
