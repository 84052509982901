@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&xmedium=48em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: "Roboto-Regular", Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 66.25rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 48em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 48em) {
  .xmedium-1 {
    width: 8.33333%; }
  .xmedium-push-1 {
    position: relative;
    left: 8.33333%; }
  .xmedium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xmedium-offset-0 {
    margin-left: 0%; }
  .xmedium-2 {
    width: 16.66667%; }
  .xmedium-push-2 {
    position: relative;
    left: 16.66667%; }
  .xmedium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xmedium-offset-1 {
    margin-left: 8.33333%; }
  .xmedium-3 {
    width: 25%; }
  .xmedium-push-3 {
    position: relative;
    left: 25%; }
  .xmedium-pull-3 {
    position: relative;
    left: -25%; }
  .xmedium-offset-2 {
    margin-left: 16.66667%; }
  .xmedium-4 {
    width: 33.33333%; }
  .xmedium-push-4 {
    position: relative;
    left: 33.33333%; }
  .xmedium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xmedium-offset-3 {
    margin-left: 25%; }
  .xmedium-5 {
    width: 41.66667%; }
  .xmedium-push-5 {
    position: relative;
    left: 41.66667%; }
  .xmedium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xmedium-offset-4 {
    margin-left: 33.33333%; }
  .xmedium-6 {
    width: 50%; }
  .xmedium-push-6 {
    position: relative;
    left: 50%; }
  .xmedium-pull-6 {
    position: relative;
    left: -50%; }
  .xmedium-offset-5 {
    margin-left: 41.66667%; }
  .xmedium-7 {
    width: 58.33333%; }
  .xmedium-push-7 {
    position: relative;
    left: 58.33333%; }
  .xmedium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xmedium-offset-6 {
    margin-left: 50%; }
  .xmedium-8 {
    width: 66.66667%; }
  .xmedium-push-8 {
    position: relative;
    left: 66.66667%; }
  .xmedium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xmedium-offset-7 {
    margin-left: 58.33333%; }
  .xmedium-9 {
    width: 75%; }
  .xmedium-push-9 {
    position: relative;
    left: 75%; }
  .xmedium-pull-9 {
    position: relative;
    left: -75%; }
  .xmedium-offset-8 {
    margin-left: 66.66667%; }
  .xmedium-10 {
    width: 83.33333%; }
  .xmedium-push-10 {
    position: relative;
    left: 83.33333%; }
  .xmedium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xmedium-offset-9 {
    margin-left: 75%; }
  .xmedium-11 {
    width: 91.66667%; }
  .xmedium-push-11 {
    position: relative;
    left: 91.66667%; }
  .xmedium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xmedium-offset-10 {
    margin-left: 83.33333%; }
  .xmedium-12 {
    width: 100%; }
  .xmedium-offset-11 {
    margin-left: 91.66667%; }
  .xmedium-up-1 > .column, .xmedium-up-1 > .columns {
    float: left;
    width: 100%; }
    .xmedium-up-1 > .column:nth-of-type(1n), .xmedium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-1 > .column:nth-of-type(1n+1), .xmedium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xmedium-up-1 > .column:last-child, .xmedium-up-1 > .columns:last-child {
      float: left; }
  .xmedium-up-2 > .column, .xmedium-up-2 > .columns {
    float: left;
    width: 50%; }
    .xmedium-up-2 > .column:nth-of-type(1n), .xmedium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-2 > .column:nth-of-type(2n+1), .xmedium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xmedium-up-2 > .column:last-child, .xmedium-up-2 > .columns:last-child {
      float: left; }
  .xmedium-up-3 > .column, .xmedium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xmedium-up-3 > .column:nth-of-type(1n), .xmedium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-3 > .column:nth-of-type(3n+1), .xmedium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xmedium-up-3 > .column:last-child, .xmedium-up-3 > .columns:last-child {
      float: left; }
  .xmedium-up-4 > .column, .xmedium-up-4 > .columns {
    float: left;
    width: 25%; }
    .xmedium-up-4 > .column:nth-of-type(1n), .xmedium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-4 > .column:nth-of-type(4n+1), .xmedium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xmedium-up-4 > .column:last-child, .xmedium-up-4 > .columns:last-child {
      float: left; }
  .xmedium-up-5 > .column, .xmedium-up-5 > .columns {
    float: left;
    width: 20%; }
    .xmedium-up-5 > .column:nth-of-type(1n), .xmedium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-5 > .column:nth-of-type(5n+1), .xmedium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xmedium-up-5 > .column:last-child, .xmedium-up-5 > .columns:last-child {
      float: left; }
  .xmedium-up-6 > .column, .xmedium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xmedium-up-6 > .column:nth-of-type(1n), .xmedium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-6 > .column:nth-of-type(6n+1), .xmedium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xmedium-up-6 > .column:last-child, .xmedium-up-6 > .columns:last-child {
      float: left; }
  .xmedium-up-7 > .column, .xmedium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xmedium-up-7 > .column:nth-of-type(1n), .xmedium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-7 > .column:nth-of-type(7n+1), .xmedium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xmedium-up-7 > .column:last-child, .xmedium-up-7 > .columns:last-child {
      float: left; }
  .xmedium-up-8 > .column, .xmedium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xmedium-up-8 > .column:nth-of-type(1n), .xmedium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-8 > .column:nth-of-type(8n+1), .xmedium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xmedium-up-8 > .column:last-child, .xmedium-up-8 > .columns:last-child {
      float: left; }
  .xmedium-collapse > .column, .xmedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xmedium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xmedium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xmedium-uncollapse > .column, .xmedium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xmedium-centered {
    margin-right: auto;
    margin-left: auto; }
    .xmedium-centered, .xmedium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xmedium-uncentered,
  .xmedium-push-0,
  .xmedium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.row {
  max-width: 66.25rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 48em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 48em) {
  .xmedium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xmedium-offset-0 {
    margin-left: 0%; }
  .xmedium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xmedium-offset-1 {
    margin-left: 8.33333%; }
  .xmedium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xmedium-offset-2 {
    margin-left: 16.66667%; }
  .xmedium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xmedium-offset-3 {
    margin-left: 25%; }
  .xmedium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xmedium-offset-4 {
    margin-left: 33.33333%; }
  .xmedium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xmedium-offset-5 {
    margin-left: 41.66667%; }
  .xmedium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xmedium-offset-6 {
    margin-left: 50%; }
  .xmedium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xmedium-offset-7 {
    margin-left: 58.33333%; }
  .xmedium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xmedium-offset-8 {
    margin-left: 66.66667%; }
  .xmedium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xmedium-offset-9 {
    margin-left: 75%; }
  .xmedium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xmedium-offset-10 {
    margin-left: 83.33333%; }
  .xmedium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xmedium-offset-11 {
    margin-left: 91.66667%; }
  .xmedium-up-1 {
    flex-wrap: wrap; }
    .xmedium-up-1 > .column, .xmedium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xmedium-up-2 {
    flex-wrap: wrap; }
    .xmedium-up-2 > .column, .xmedium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xmedium-up-3 {
    flex-wrap: wrap; }
    .xmedium-up-3 > .column, .xmedium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xmedium-up-4 {
    flex-wrap: wrap; }
    .xmedium-up-4 > .column, .xmedium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xmedium-up-5 {
    flex-wrap: wrap; }
    .xmedium-up-5 > .column, .xmedium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xmedium-up-6 {
    flex-wrap: wrap; }
    .xmedium-up-6 > .column, .xmedium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xmedium-up-7 {
    flex-wrap: wrap; }
    .xmedium-up-7 > .column, .xmedium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xmedium-up-8 {
    flex-wrap: wrap; }
    .xmedium-up-8 > .column, .xmedium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .xmedium-expand {
    flex: 1 1 0px; } }

.row.xmedium-unstack > .column, .row.xmedium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 48em) {
    .row.xmedium-unstack > .column, .row.xmedium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 48em) {
  .xmedium-collapse > .column, .xmedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xmedium-uncollapse > .column, .xmedium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Roboto-Regular", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #a49e97; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1468a0; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 66.25rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #a49e97;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #a49e97; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #7d756b; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #7d756b; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #a49e97;
  background-color: #f2f1f0;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #f2f1f0;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #7d756b; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 48em) {
  .xmedium-text-left {
    text-align: left; }
  .xmedium-text-right {
    text-align: right; }
  .xmedium-text-center {
    text-align: center; }
  .xmedium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #7d756b;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #ffffff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #ffffff; }
  .button.secondary {
    background-color: #767676;
    color: #ffffff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #ffffff; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #ffffff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #ffffff; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #ffffff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #ffffff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #ffffff; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: none;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: none;
    background-color: #ffffff;
    box-shadow: none;
    transition: border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #a49e97; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #f2f1f0;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #a49e97;
  background: #f2f1f0;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #a49e97; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28125, 117, 107%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: none;
    background-color: #ffffff;
    box-shadow: none;
    transition: border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #f2f1f0;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #ffffff;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 0 0;
  border: 0;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 0;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: transparent; }

.accordion-content {
  display: none;
  padding: 0;
  border: 0;
  border-bottom: 0;
  background-color: #ffffff;
  color: #7d756b; }
  :last-child > .accordion-content:last-child {
    border-bottom: 0; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #ffffff; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #ffffff; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #ffffff; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #ffffff; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #ffffff; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #ffffff; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 48em) {
    .menu.xmedium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xmedium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xmedium-expanded li {
      flex: 1 1 0px; }
    .menu.xmedium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #1779ba;
    color: #ffffff; }
  .menu .active > a {
    background: #1779ba;
    color: #ffffff; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #ffffff;
    box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
    content: ''; }
  .menu-icon:hover::after {
    background: #a49e97;
    box-shadow: 0 7px 0 #a49e97, 0 14px 0 #a49e97; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #7d756b;
    box-shadow: 0 7px 0 #7d756b, 0 14px 0 #7d756b; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #ffffff; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #ffffff;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #a49e97;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 48em) {
  .dropdown.menu.xmedium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xmedium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xmedium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xmedium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xmedium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xmedium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xmedium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xmedium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xmedium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.xmedium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #a49e97;
  background: #ffffff; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f2f2f2;
    background-color: #ffffff; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f9f9f9;
    color: #0a0a0a; }
  table tfoot {
    background: #f2f2f2;
    color: #0a0a0a; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #ffffff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #ffffff; }

@media screen and (max-width: 47.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #7d756b;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #ffffff; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #f2f1f0; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 47.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-xmedium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-xmedium .top-bar-left,
      .top-bar.stacked-for-xmedium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 47.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 48em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-xmedium {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-xmedium {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-xmedium-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-xmedium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 48em) {
  .xmedium-order-1 {
    order: 1; }
  .xmedium-order-2 {
    order: 2; }
  .xmedium-order-3 {
    order: 3; }
  .xmedium-order-4 {
    order: 4; }
  .xmedium-order-5 {
    order: 5; }
  .xmedium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 48em) {
  .xmedium-flex-container {
    display: flex; }
  .xmedium-flex-child-auto {
    flex: 1 1 auto; }
  .xmedium-flex-child-grow {
    flex: 1 0 auto; }
  .xmedium-flex-child-shrink {
    flex: 0 1 auto; }
  .xmedium-flex-dir-row {
    flex-direction: row; }
  .xmedium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xmedium-flex-dir-column {
    flex-direction: column; }
  .xmedium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

/*
*   COLORS
*/
/*
*   FONTS
*/
/*
*   FORM
*/
/*
*   PATHS
*/
/*
*   FONTS
*/
/*
*   IMAGES, RETINA IMAGES
*/
/*
*   PLACEHOLDER
*/
/*
*   ASPECT RATIO
*/
/*
*   BACKGROUNDS
*/
/*
*   BORDERS
*/
/*
*   FONT WEIGHT
*/
strong, bold, .bold {
  font-family: "font-family-bold", Arial, Helvetica, sans-serif; }

/*
*   HELPER CLASSES
*/
.hidden-content {
  display: none; }

/* Läuft bis Browserrand */
.wrapper-fullwidth {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: initial; }

/* Sorgt für gewünschten Abstand zum Browser*/
.wrapper-page {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;
  position: relative; }
  @media screen and (min-width: 40em) {
    .wrapper-page {
      padding-left: 0px;
      padding-right: 00px; } }

.nopadding {
  padding: 0; }

/*
*   TOGGLE MENU
*/
.toggle-menu {
  position: relative; }
  .toggle-menu .action-close {
    display: none; }
  .toggle-menu.active .action-open {
    display: none; }
  .toggle-menu.active .action-close {
    display: inline-block; }

.c-bg-light-gray {
  background-color: #f2f1f0; }

.c-bg-medium-blue {
  background-color: #b4ccd8; }

.c-bg-dark-gray {
  background-color: #7d756b; }

/* webkit-tap-highlight-color */
* {
  -webkit-tap-highlight-color: transparent; }

/* To set it to none use a totally transparent RGBA */
a:focus {
  color: #d2e0e8; }

/*
*   HTML, BODY
*/
html, body {
  height: 100%;
  color: #7d756b; }

.page-wrap {
  min-height: 100%; }
  @media screen and (max-width: 74.9375em) {
    .page-wrap {
      overflow: hidden; } }

.content {
  padding-top: 25px;
  padding-bottom: 30px; }
  @media screen and (min-width: 40em) {
    .content {
      padding-top: 45px;
      padding-bottom: 60px; } }
  .content .h1:first-child {
    margin-bottom: 2rem; }
  .content .h2:first-child {
    margin-bottom: 2rem; }
  .content.headline-only {
    padding-top: 30px;
    padding-bottom: 30px; }
    @media screen and (min-width: 40em) {
      .content.headline-only {
        padding-top: 60px;
        padding-bottom: 60px; } }
  .content.no-background + .content.no-background {
    padding-top: 0; }
  .content.first {
    margin-top: 0; }
    @media screen and (min-width: 40em) {
      .content.first {
        margin-top: 120px; } }
  .content.subpage {
    padding-top: 60px;
    padding-bottom: 60px; }
  .content.berater-stage {
    padding-bottom: 60px; }
    @media screen and (min-width: 40em) {
      .content.berater-stage {
        padding-bottom: 250px; } }
  .content.berater-content {
    padding-top: 0; }
  @media screen and (min-width: 40em) {
    .content.referenzen {
      padding-bottom: 50px;
      min-height: 680px; } }
  .content.berater {
    padding-bottom: 220px;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 47.9375em) {
      .content.berater {
        padding-bottom: 300px; } }
    .content.berater .bg-circle {
      background-image: url("../img/circles_blue_trans.png");
      background-repeat: no-repeat;
      width: 534px;
      height: 733px;
      display: inline-block;
      /*            background: url(../img/circles_blue_trans.png) no-repeat bottom right;*/
      right: -150px;
      bottom: -350px;
      position: absolute;
      z-index: -1; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .content.berater .bg-circle {
          background-image: url("../img/2x/circles_blue_trans_2x.png");
          background-size: 100% auto;
          background-repeat: no-repeat; } }
      @media screen and (max-width: 47.9375em) {
        .content.berater .bg-circle {
          right: inherit;
          bottom: -500px; } }
    .content.berater .container-berater {
      position: relative; }
      .content.berater .container-berater .images-berater {
        position: absolute;
        right: -120px;
        bottom: -220px; }
        .content.berater .container-berater .images-berater .back {
          background-image: url(../img/berater/berater_back.png);
          background-repeat: no-repeat;
          width: 423px;
          height: 380px;
          /*                    @include image-png ("berater/berater_back", 460px, 386px);*/ }
        .content.berater .container-berater .images-berater .front {
          background-image: url(../img/berater/berater_front.png);
          background-repeat: no-repeat;
          width: 329px;
          height: 352px;
          /*                    @include image-png ("berater/berater_front", 329px, 352px);      */
          position: absolute;
          right: 310px;
          bottom: 0;
          z-index: 1; }
        @media screen and (max-width: 47.9375em) {
          .content.berater .container-berater .images-berater {
            bottom: -425px;
            right: -180px; }
            .content.berater .container-berater .images-berater .back, .content.berater .container-berater .images-berater .front {
              background-size: 70%; }
            .content.berater .container-berater .images-berater .front {
              right: 280px; } }
  .content.footer {
    padding-bottom: 15px; }

.origami-pattern {
  background-image: url(../img/pattern_ongrey.png);
  background-repeat: repeat-y;
  background-position: center center; }
  .origami-pattern.c-bg-dark-gray {
    background-image: url(../img/pattern_onbrown.png); }

.origami-white-pattern {
  background-repeat: repeat-y;
  background-position: center center; }

.circle-blue-pattern {
  background-image: url(../img/circles_blue_trans.png);
  background-repeat: repeat-y;
  background-position: right center; }

section.header {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 100; }
  @media screen and (max-width: 74.9375em) {
    section.header {
      max-width: inherit;
      margin: auto;
      left: inherit; } }
  @media screen and (max-width: 39.9375em) {
    section.header {
      position: relative; } }
  section.header .site-header .wrapper-page {
    padding-top: 20px; }
  section.header .site-header .logo-bar .logo {
    background-image: url("../img/logo.png");
    background-repeat: no-repeat;
    width: 93px;
    height: 99px;
    display: inline-block;
    background-repeat: no-repeat;
    display: inline-block;
    float: left; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      section.header .site-header .logo-bar .logo {
        background-image: url("../img/2x/logo_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  section.header .site-header.mobile {
    display: block; }
    @media screen and (max-width: 39.9375em) {
      section.header .site-header.mobile .logo {
        width: 48px;
        height: 50px;
        background-size: auto 100%; } }
    @media screen and (min-width: 64em) {
      section.header .site-header.mobile {
        display: none; } }
  section.header .site-header.desktop {
    display: none; }
    @media screen and (min-width: 64em) {
      section.header .site-header.desktop {
        display: block; } }

.cc-window {
  bottom: 0; }

.cc-revoke, .cc-window {
  font-family: 'Roboto-Regular'; }
  @media screen and (max-width: 47.9375em) {
    .cc-revoke, .cc-window {
      font-size: 14px; } }

.cc-window.cc-floating {
  max-width: none;
  width: 100%; }

.cc-btn {
  text-transform: uppercase;
  border-radius: 0;
  display: inline-block;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.1em; }

.cc-link {
  display: none; }

.cc-message {
  padding-right: 20px; }

.cc-banner .cc-message {
  -ms-flex: 1;
  flex: 1; }

@media screen and (max-width: 47.9375em) {
  .cc-window.cc-floating {
    padding: 1rem; } }

.cc-compliance > .cc-btn {
  flex: none; }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v18-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v18-latin-regular.woff") format("woff"), url("../fonts/roboto-v18-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v18-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto-Medium';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v18-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v18-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-500.woff2") format("woff2"), url("../fonts/roboto-v18-latin-500.woff") format("woff"), url("../fonts/roboto-v18-latin-500.ttf") format("truetype"), url("../fonts/roboto-v18-latin-500.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/*
*   DEFAULT
*/
/*
*   HEADERS
*/
h1, .h1 {
  font-size: 60px;
  font-family: 'Roboto-Medium';
  line-height: 1em; }

h2, .h2 {
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

h3, .h3 {
  font-size: 24px;
  letter-spacing: 0.2em;
  margin-bottom: 0.8rem;
  font-family: 'Roboto-Medium';
  /*    &.animationComplete{
        transition: all 0.25s ease-in-out !important;
    }*/ }

h4, .h4 {
  font-size: 20px;
  letter-spacing: 0.15em;
  margin-bottom: 0.8rem;
  font-family: 'Roboto-Medium'; }

h6, .h6 {
  font-size: 12px;
  letter-spacing: 0.3em;
  margin-bottom: 0.8rem; }

.medium {
  font-family: 'Roboto-Medium'; }

.cdark-gray {
  color: #7d756b; }

.cdark-blue {
  color: #667f8b; }

.cwhite {
  color: #fff; }

.cmedium-blue {
  color: #b4ccd8; }

.text-link-list p {
  margin-bottom: 0.75rem; }

.text-link-list .text-link {
  display: inline;
  line-height: 1.875rem; }

.text-link-list .text-link-style {
  display: inline;
  line-height: 1.5rem; }

.text-link {
  display: inline-block;
  border-bottom: 2px solid #667f8b;
  color: #667f8b;
  font-family: 'Roboto-Regular';
  letter-spacing: 0.1rem;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 6px;
  transition: all 0.25s ease-in-out; }
  .text-link.animationComplete {
    transition: all 0.25s ease-in-out !important; }
  .text-link:hover, .text-link:focus {
    color: #b4ccd8 !important;
    border-color: #b4ccd8 !important; }

.text-link-style {
  display: inline-block;
  color: #667f8b;
  font-family: 'Roboto-Regular';
  letter-spacing: 0.1rem;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 6px; }

.uppercase {
  text-transform: uppercase; }

.quote {
  font-size: 1rem;
  font-style: italic; }

/*
*   LINKS AND BUTTONS
*/
a {
  color: inherit; }
  a:hover {
    color: #d2e0e8; }

p {
  color: #7d756b; }
  p.enumeration {
    line-height: 1.8; }
  p.last {
    margin-bottom: 0; }

.small {
  font-family: 'Roboto-Medium';
  letter-spacing: 0.1rem;
  font-size: 12px;
  text-transform: uppercase; }

.xsmall {
  font-family: 'Roboto-Regular';
  letter-spacing: 0.3rem;
  font-size: 12px;
  text-transform: uppercase; }

@media screen and (max-width: 74.9375em) {
  h1, .h1 {
    font-size: 36px; } }

@keyframes blurIn {
  0% {
    filter: blur(15px);
    transform: translateY(20px);
    opacity: 0; }
  100% {
    filter: blur(0px);
    transform: translateY(0px);
    opacity: 1; } }

@keyframes blurOut {
  0% {
    filter: blur(0px);
    transform: translateY(0px);
    opacity: 1; }
  100% {
    filter: blur(15px);
    transform: translateY(20px);
    opacity: 0; } }

@keyframes blurInOut {
  0% {
    filter: blur(15px);
    transform: translateY(20px);
    opacity: 0; }
  20%, 80% {
    filter: blur(0px);
    transform: translateY(0px);
    opacity: 1; }
  100% {
    filter: blur(15px);
    transform: translateY(20px);
    opacity: 0; } }

.animation-blurIn {
  position: relative;
  animation: blurIn 1.5s ease-in-out forwards; }
  @media screen and (max-width: 39.9375em) {
    .animation-blurIn.fulls-c-2, .animation-blurIn.fulls-c-3 {
      animation: none; } }

.animation-blurOut {
  position: relative;
  animation: blurOut 1.5s ease-in-out forwards; }

.animation-content-blur-start > div {
  opacity: 0; }

.animation-content-blur-2 {
  position: relative;
  opacity: 0; }
  @media screen and (max-width: 47.9375em) {
    .animation-content-blur-2 {
      opacity: 1; } }

.animation-content-blur-3 {
  position: relative;
  opacity: 0; }
  @media screen and (max-width: 47.9375em) {
    .animation-content-blur-3 {
      opacity: 1; } }

.animation-loop {
  position: relative; }
  .animation-loop .animation-loop-part {
    position: absolute;
    opacity: 0;
    width: 100%;
    max-width: 100%; }
    .animation-loop .animation-loop-part.active {
      animation-name: blurInOut;
      animation-duration: 5s;
      animation-iteration-count: 1; }

@keyframes growUpCircle {
  0% {
    width: 40px;
    height: 40px; }
  30% {
    width: 35px;
    height: 35px;
    top: -2.5px; }
  90% {
    width: 50px;
    height: 50px;
    top: 5px; }
  100% {
    width: 45px;
    height: 45px;
    top: 2.5px; } }

@keyframes growDownCircle {
  0% {
    width: 45px;
    height: 45px;
    top: 2.5px; }
  30% {
    width: 50px;
    height: 50px;
    top: 5px; }
  90% {
    width: 35px;
    height: 35px;
    top: -2.5px; }
  100% {
    width: 40px;
    height: 40px; } }

@keyframes animateUpDownArrow {
  0% {
    top: 50%;
    opacity: 1; }
  50% {
    top: 56%;
    opacity: 0.5; }
  100% {
    top: 50%;
    opacity: 1; } }

@keyframes animateRightLeftArrow {
  0% {
    right: 50%;
    opacity: 1; }
  50% {
    right: 44%;
    opacity: 0.5; }
  100% {
    right: 50%;
    opacity: 1; } }

@keyframes animateLeftRightArrow {
  0% {
    left: 50%;
    opacity: 1; }
  50% {
    left: 44%;
    opacity: 0.5; }
  100% {
    left: 50%;
    opacity: 1; } }

.icon-circle-arrow {
  display: inline-block;
  border-radius: 50%;
  text-align: center; }
  .icon-circle-arrow .title {
    position: absolute;
    top: -20px;
    width: 200px;
    margin-left: -100px;
    left: 50%;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 12px; }
    @media screen and (max-width: 74.9375em) {
      .icon-circle-arrow .title {
        top: -15px; } }
  .icon-circle-arrow .bottom, .icon-circle-arrow .top, .icon-circle-arrow .left, .icon-circle-arrow .right {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid #667f8b;
    border-radius: 50%;
    position: relative;
    transition: all 0.4s ease-in-out; }
    .icon-circle-arrow .bottom:after, .icon-circle-arrow .top:after, .icon-circle-arrow .left:after, .icon-circle-arrow .right:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-top: 2px solid #667f8b;
      border-right: 2px solid #667f8b;
      transition: all 0.4s ease-in-out; }
    .icon-circle-arrow .bottom:hover, .icon-circle-arrow .bottom:active, .icon-circle-arrow .top:hover, .icon-circle-arrow .top:active, .icon-circle-arrow .left:hover, .icon-circle-arrow .left:active, .icon-circle-arrow .right:hover, .icon-circle-arrow .right:active {
      border: 2px solid #b4ccd8; }
      .icon-circle-arrow .bottom:hover:after, .icon-circle-arrow .bottom:active:after, .icon-circle-arrow .top:hover:after, .icon-circle-arrow .top:active:after, .icon-circle-arrow .left:hover:after, .icon-circle-arrow .left:active:after, .icon-circle-arrow .right:hover:after, .icon-circle-arrow .right:active:after {
        border-top: 2px solid #b4ccd8;
        border-right: 2px solid #b4ccd8;
        animation: animateUpDownArrow 1.5s infinite 0.5s; }
  .icon-circle-arrow .close {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid #667f8b;
    border-radius: 50%;
    position: relative;
    transition: all 0.4s ease-in-out; }
    .icon-circle-arrow .close:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-top: 2px solid #667f8b;
      border-right: 2px solid #667f8b;
      transition: all 0.4s ease-in-out; }
    .icon-circle-arrow .close:hover, .icon-circle-arrow .close:active {
      border: 2px solid #b4ccd8; }
      .icon-circle-arrow .close:hover:after, .icon-circle-arrow .close:active:after {
        border-top: 2px solid #b4ccd8;
        border-right: 2px solid #b4ccd8; }
  .icon-circle-arrow .right:hover:after, .icon-circle-arrow .right:active:after {
    animation: animateRightLeftArrow 1.5s infinite 0.5s; }
  .icon-circle-arrow .left:hover:after, .icon-circle-arrow .left:active:after {
    animation: animateLeftRightArrow 1.5s infinite 0.5s; }
  .icon-circle-arrow .bottom:after {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -6px;
    margin-top: -8px;
    transform: rotate(135deg); }
  .icon-circle-arrow .top:after {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -6px;
    margin-top: -4px;
    transform: rotate(-45deg); }
  .icon-circle-arrow .left:after {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -4px;
    margin-top: -6px;
    transform: rotate(-135deg); }
  .icon-circle-arrow .right:after {
    position: absolute;
    right: 50%;
    top: 50%;
    margin-right: -4px;
    margin-top: -6px;
    transform: rotate(45deg); }
  .icon-circle-arrow .close:after {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -6px;
    margin-top: 2px;
    transform: rotate(-45deg); }
  .icon-circle-arrow .close:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-top: 2px solid #667f8b;
    border-right: 2px solid #667f8b;
    transition: all 0.4s ease-in-out;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -6px;
    margin-top: -12px;
    transform: rotate(135deg); }
  .icon-circle-arrow.white .bottom, .icon-circle-arrow.white .top, .icon-circle-arrow.white .left, .icon-circle-arrow.white .right, .icon-circle-arrow.white .close {
    border-color: #fff; }
    .icon-circle-arrow.white .bottom:after, .icon-circle-arrow.white .top:after, .icon-circle-arrow.white .left:after, .icon-circle-arrow.white .right:after, .icon-circle-arrow.white .close:after {
      border-color: #fff; }
    .icon-circle-arrow.white .bottom:hover, .icon-circle-arrow.white .bottom:active, .icon-circle-arrow.white .top:hover, .icon-circle-arrow.white .top:active, .icon-circle-arrow.white .left:hover, .icon-circle-arrow.white .left:active, .icon-circle-arrow.white .right:hover, .icon-circle-arrow.white .right:active, .icon-circle-arrow.white .close:hover, .icon-circle-arrow.white .close:active {
      border-color: #b4ccd8; }
      .icon-circle-arrow.white .bottom:hover:after, .icon-circle-arrow.white .bottom:active:after, .icon-circle-arrow.white .top:hover:after, .icon-circle-arrow.white .top:active:after, .icon-circle-arrow.white .left:hover:after, .icon-circle-arrow.white .left:active:after, .icon-circle-arrow.white .right:hover:after, .icon-circle-arrow.white .right:active:after, .icon-circle-arrow.white .close:hover:after, .icon-circle-arrow.white .close:active:after {
        border-color: #b4ccd8; }
  .icon-circle-arrow.medium-blue .bottom, .icon-circle-arrow.medium-blue .top, .icon-circle-arrow.medium-blue .left, .icon-circle-arrow.medium-blue .right {
    border-color: #b4ccd8; }
    .icon-circle-arrow.medium-blue .bottom:after, .icon-circle-arrow.medium-blue .top:after, .icon-circle-arrow.medium-blue .left:after, .icon-circle-arrow.medium-blue .right:after {
      border-color: #b4ccd8; }
    .icon-circle-arrow.medium-blue .bottom:hover, .icon-circle-arrow.medium-blue .bottom:active, .icon-circle-arrow.medium-blue .top:hover, .icon-circle-arrow.medium-blue .top:active, .icon-circle-arrow.medium-blue .left:hover, .icon-circle-arrow.medium-blue .left:active, .icon-circle-arrow.medium-blue .right:hover, .icon-circle-arrow.medium-blue .right:active {
      border-color: #667f8b; }
      .icon-circle-arrow.medium-blue .bottom:hover:after, .icon-circle-arrow.medium-blue .bottom:active:after, .icon-circle-arrow.medium-blue .top:hover:after, .icon-circle-arrow.medium-blue .top:active:after, .icon-circle-arrow.medium-blue .left:hover:after, .icon-circle-arrow.medium-blue .left:active:after, .icon-circle-arrow.medium-blue .right:hover:after, .icon-circle-arrow.medium-blue .right:active:after {
        border-color: #667f8b; }
  .icon-circle-arrow.medium-blue .close {
    border-color: #b4ccd8; }
    .icon-circle-arrow.medium-blue .close:after {
      border-color: #b4ccd8; }
    .icon-circle-arrow.medium-blue .close:before {
      border-color: #b4ccd8; }
    .icon-circle-arrow.medium-blue .close:hover, .icon-circle-arrow.medium-blue .close:active {
      border-color: #667f8b; }
      .icon-circle-arrow.medium-blue .close:hover:after, .icon-circle-arrow.medium-blue .close:active:after {
        border-color: #667f8b; }
      .icon-circle-arrow.medium-blue .close:hover:before, .icon-circle-arrow.medium-blue .close:active:before {
        border-color: #667f8b; }
  .icon-circle-arrow.dark-blue .close {
    border-color: #667f8b; }
    .icon-circle-arrow.dark-blue .close:after {
      border-color: #667f8b; }
    .icon-circle-arrow.dark-blue .close:before {
      border-color: #667f8b; }
    .icon-circle-arrow.dark-blue .close:hover, .icon-circle-arrow.dark-blue .close:active {
      border-color: #b4ccd8; }
      .icon-circle-arrow.dark-blue .close:hover:after, .icon-circle-arrow.dark-blue .close:active:after {
        border-color: #b4ccd8; }
      .icon-circle-arrow.dark-blue .close:hover:before, .icon-circle-arrow.dark-blue .close:active:before {
        border-color: #b4ccd8; }

.tel {
  color: #fff; }

.icon-key {
  background-image: url("../img/icns-key.png");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-key {
      background-image: url("../img/2x/icns-key_2x.png");
      background-size: 25px 100px;
      background-repeat: no-repeat; } }
  .icon-key.key-a {
    background-position: 0 0; }
  .icon-key.key-b {
    background-position: 0 -25px; }
  .icon-key.key-c {
    background-position: 0 -50px; }
  .icon-key.key-d {
    background-position: 0 -75px; }

.origami.bee {
  max-width: 100%;
  background-image: url("../img/origami/bee.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 152px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.bee {
      background-image: url("../img/2x/origami/bee_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.bee::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 159.21053%; }

.origami.butterfly {
  max-width: 100%;
  background-image: url("../img/origami/butterfly.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.butterfly {
      background-image: url("../img/2x/origami/butterfly_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.butterfly::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 72.04969%; }

.origami.butterfly_circle {
  max-width: 100%;
  background-image: url("../img/origami/butterfly_circle.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.butterfly_circle {
      background-image: url("../img/2x/origami/butterfly_circle_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.butterfly_circle::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 95.03106%; }

.origami.dolphin {
  max-width: 100%;
  background-image: url("../img/origami/dolphin.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 342px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.dolphin {
      background-image: url("../img/2x/origami/dolphin_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.dolphin::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 59.06433%; }

.origami.dragonfly {
  max-width: 100%;
  background-image: url("../img/origami/dragonfly.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 321px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.dragonfly {
      background-image: url("../img/2x/origami/dragonfly_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.dragonfly::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 89.09657%; }

.origami.frog {
  max-width: 100%;
  background-image: url("../img/origami/frog.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 320px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.frog {
      background-image: url("../img/2x/origami/frog_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.frog::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 59.6875%; }

.origami.instruction-1 {
  max-width: 100%;
  background-image: url("../img/origami/instruction-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.instruction-1 {
      background-image: url("../img/2x/origami/instruction-1_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.instruction-1::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100.31056%; }

.origami.instruction-2 {
  max-width: 100%;
  background-image: url("../img/origami/instruction-2.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 286px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.instruction-2 {
      background-image: url("../img/2x/origami/instruction-2_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.instruction-2::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 80.06993%; }

.origami.instruction-2.normalized {
  max-width: 100%;
  background-image: url("../img/origami/instruction-2-normalized.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.instruction-2.normalized {
      background-image: url("../img/2x/origami/instruction-2-normalized_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.instruction-2.normalized::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100.31056%; }

.origami.instruction-3 {
  max-width: 100%;
  background-image: url("../img/origami/instruction-3.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 213px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.instruction-3 {
      background-image: url("../img/2x/origami/instruction-3_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.instruction-3::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 150.23474%; }

.origami.instruction-3.normalized {
  max-width: 100%;
  background-image: url("../img/origami/instruction-3-normalized.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.instruction-3.normalized {
      background-image: url("../img/2x/origami/instruction-3-normalized_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.instruction-3.normalized::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100.31056%; }

.origami.lion {
  max-width: 100%;
  background-image: url("../img/origami/lion.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.lion {
      background-image: url("../img/2x/origami/lion_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.lion::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 57.14286%; }

.origami.model.engagement {
  max-width: 100%;
  background-image: url("../img/origami/model-engagement-mobile.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.model.engagement {
      background-image: url("../img/2x/origami/model-engagement-mobile_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.model.engagement::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%; }
  @media screen and (min-width: 48em) {
    .origami.model.engagement {
      max-width: 100%;
      background-image: url("../img/origami/model-engagement.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 320px;
      display: inline-block; } }
  @media screen and (min-width: 48em) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 48em) and (min-resolution: 192dpi) {
    .origami.model.engagement {
      background-image: url("../img/2x/origami/model-engagement_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  @media screen and (min-width: 48em) {
      .origami.model.engagement::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%; } }

.origami.model.learning {
  max-width: 100%;
  background-image: url("../img/origami/model-learning-mobile.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.model.learning {
      background-image: url("../img/2x/origami/model-learning-mobile_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.model.learning::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%; }
  @media screen and (min-width: 48em) {
    .origami.model.learning {
      max-width: 100%;
      background-image: url("../img/origami/model-learning.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 320px;
      display: inline-block; } }
  @media screen and (min-width: 48em) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 48em) and (min-resolution: 192dpi) {
    .origami.model.learning {
      background-image: url("../img/2x/origami/model-learning_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  @media screen and (min-width: 48em) {
      .origami.model.learning::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%; } }

.origami.model.strategy {
  max-width: 100%;
  background-image: url("../img/origami/model-strategy-mobile.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.model.strategy {
      background-image: url("../img/2x/origami/model-strategy-mobile_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.model.strategy::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%; }
  @media screen and (min-width: 48em) {
    .origami.model.strategy {
      max-width: 100%;
      background-image: url("../img/origami/model-strategy.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 320px;
      display: inline-block; } }
  @media screen and (min-width: 48em) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 48em) and (min-resolution: 192dpi) {
    .origami.model.strategy {
      background-image: url("../img/2x/origami/model-strategy_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  @media screen and (min-width: 48em) {
      .origami.model.strategy::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%; } }

.origami.parrot {
  max-width: 100%;
  background-image: url("../img/origami/parrot.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 225px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.parrot {
      background-image: url("../img/2x/origami/parrot_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.parrot::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 116.44444%; }

.origami.rabbit {
  max-width: 100%;
  background-image: url("../img/origami/rabbit.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 152px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.rabbit {
      background-image: url("../img/2x/origami/rabbit_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.rabbit::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 116.44737%; }

.origami.owl {
  max-width: 100%;
  background-image: url("../img/origami/owl.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 205px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.owl {
      background-image: url("../img/2x/origami/owl_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.owl::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 127.80488%; }

.origami.bull {
  max-width: 100%;
  background-image: url("../img/origami/bull.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .origami.bull {
      background-image: url("../img/2x/origami/bull_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  .origami.bull::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 59.31677%; }

.origami-instruction-loop {
  max-width: 50%;
  padding-bottom: 50%;
  margin: 30px auto 60px auto; }
  @media screen and (min-width: 40em) {
    .origami-instruction-loop {
      max-width: 323px;
      padding-bottom: 100%;
      margin: 0 auto; } }

.arrow {
  position: relative;
  display: inline-block; }
  .arrow::before {
    content: '';
    position: absolute; }
  .arrow.left {
    padding-left: 87px; }
    .arrow.left::before {
      left: 0; }
  .arrow.right {
    padding-left: 87px; }
    .arrow.right::before {
      left: 0; }
    @media screen and (min-width: 40em) {
      .arrow.right.large-up {
        padding-left: 87px;
        padding-right: 0; }
        .arrow.right.large-up::before {
          left: 0;
          right: auto; } }
    @media screen and (min-width: 64em) {
      .arrow.right.large-up {
        padding-left: 0;
        padding-right: 87px; }
        .arrow.right.large-up::before {
          left: auto;
          right: 0; } }
    @media screen and (min-width: 40em) {
      .arrow.right {
        padding-left: 0;
        padding-right: 87px; }
        .arrow.right::before {
          left: auto;
          right: 0; } }
  .arrow.flip::before {
    transform: scaleX(-1); }
  .arrow.arrow-1::before {
    background-image: url("../img/arrows/arrow-1.png");
    background-repeat: no-repeat;
    width: 67px;
    height: 38px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .arrow.arrow-1::before {
        background-image: url("../img/2x/arrows/arrow-1_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  .arrow.arrow-2::before {
    background-image: url("../img/arrows/arrow-2.png");
    background-repeat: no-repeat;
    width: 67px;
    height: 38px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .arrow.arrow-2::before {
        background-image: url("../img/2x/arrows/arrow-2_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  .arrow.arrow-3::before {
    background-image: url("../img/arrows/arrow-3.png");
    background-repeat: no-repeat;
    width: 67px;
    height: 38px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .arrow.arrow-3::before {
        background-image: url("../img/2x/arrows/arrow-3_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  .arrow.arrow-4::before {
    background-image: url("../img/arrows/arrow-4.png");
    background-repeat: no-repeat;
    width: 67px;
    height: 38px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .arrow.arrow-4::before {
        background-image: url("../img/2x/arrows/arrow-4_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  .arrow.arrow-5::before {
    background-image: url("../img/arrows/arrow-5.png");
    background-repeat: no-repeat;
    width: 67px;
    height: 38px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .arrow.arrow-5::before {
        background-image: url("../img/2x/arrows/arrow-5_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  .arrow.arrow-6::before {
    background-image: url("../img/arrows/arrow-6.png");
    background-repeat: no-repeat;
    width: 67px;
    height: 38px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .arrow.arrow-6::before {
        background-image: url("../img/2x/arrows/arrow-6_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  .arrow.arrow-1::before {
    top: calc((50% - 38px) + 17px); }
  .arrow.arrow-2::before {
    top: 4px; }
  .arrow.arrow-3::before {
    top: calc((50% - 38px) + 19px); }
  .arrow.arrow-4::before {
    top: calc((50% - 38px) + 11px); }
  .arrow.arrow-5::before {
    top: calc((50% - 38px) + 13px); }
  .arrow.arrow-6::before {
    top: calc((50% - 38px) + 18px); }

.brace {
  display: block;
  width: 65px;
  float: left;
  background-image: url(../img/brace.svg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%; }
  .brace + .enumeration {
    margin-left: 95px; }
    @media screen and (max-width: 47.9375em) {
      .brace + .enumeration {
        margin-left: 85px; } }
  .brace.flip {
    transform: scaleX(-1); }

@media screen and (max-width: 47.9375em) {
  .columns.image-only img, .columns.image-only .origami {
    margin-bottom: 25px; } }

.quick-contact {
  margin-top: 50px; }
  @media screen and (max-width: 47.9375em) {
    .quick-contact {
      margin-top: 30px; } }

form {
  /*
    *   CHOSEN
    */
  /*
    *   ICHECK
    */ }
  form input[type="text"], form textarea, form select {
    appearance: none;
    border-top-style: none;
    border-top-width: 0;
    border-right-style: none;
    border-right-width: 0;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #b4ccd8;
    border-left-style: none;
    border-left-width: 0;
    box-shadow: none;
    color: #7d756b;
    margin: 0 0 0.625rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
    @media screen and (max-width: 39.9375em) {
      form input[type="text"], form textarea, form select {
        padding-left: 0;
        padding-right: 0; } }
    form input[type="text"]:focus, form textarea:focus, form select:focus {
      border-bottom-style: solid;
      border-bottom-width: 2px;
      border-bottom-color: #667f8b; }
  form textarea {
    padding: 5px 30px 5px 30px;
    line-height: 22px;
    height: 78px !important; }
    @media screen and (max-width: 39.9375em) {
      form textarea {
        padding: 16px 0px 0 0px; } }
  form input::placeholder, form textarea::placeholder {
    color: #7d756b;
    opacity: 1; }
  form input::-webkit-input-placeholder {
    color: #7d756b;
    opacity: 1; }
  form input::-moz-placeholder {
    color: #7d756b;
    opacity: 1; }
  form input:-ms-input-placeholder {
    color: #7d756b;
    opacity: 1; }
  form input:-moz-placeholder {
    color: #7d756b;
    opacity: 1; }
  form input[type="text"], form select {
    height: 34px;
    line-height: 20px; }
  form input[type="text"].error {
    border-bottom: solid 2px #b02020; }
  form textarea {
    resize: none; }
  form label {
    display: inline-block;
    line-height: 35px;
    color: #7d756b;
    letter-spacing: 0.05em;
    font-size: 16px; }
    form label.invisible {
      display: block;
      position: absolute;
      width: 0px;
      height: 0px; }
  form .phone-row {
    display: none;
    padding-top: 20px; }
  form .radio-wrapper label, form .checkbox-wrapper label {
    padding-left: 5px;
    padding-right: 30px; }
    @media screen and (max-width: 39.9375em) {
      form .radio-wrapper label, form .checkbox-wrapper label {
        padding-left: 5px; }
        form .radio-wrapper label:last-child, form .checkbox-wrapper label:last-child {
          padding-right: 0px; } }
  form .icheckbox {
    background-image: url("../img/checkbox.png");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      form .icheckbox {
        background-image: url("../img/2x/checkbox_2x.png");
        background-size: 20px 100px;
        background-repeat: no-repeat; } }
  form .iradio {
    background-image: url("../img/radio.png");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      form .iradio {
        background-image: url("../img/2x/radio_2x.png");
        background-size: 20px 100px;
        background-repeat: no-repeat; } }
  form .icheckbox, form .iradio {
    cursor: pointer;
    top: 4px; }
    form .icheckbox.hover, form .iradio.hover {
      background-position: 0 -20px; }
    form .icheckbox.checked, form .iradio.checked {
      background-position: 0 -40px; }
    form .icheckbox.checked.hover, form .iradio.checked.hover {
      background-position: 0 -60px; }
    form .icheckbox.error, form .iradio.error {
      background-position: 0 -80px; }
      form .icheckbox.error.checked, form .iradio.error.checked {
        background-position: 0 -40px; }

.contact-overlay {
  position: relative;
  min-height: 800px; }
  .contact-overlay .intro {
    margin-bottom: 45px; }
    .contact-overlay .intro h2 {
      font-size: 1.5rem;
      margin-bottom: 20px; }
  .contact-overlay label {
    padding-left: 30px; }
    @media screen and (max-width: 39.9375em) {
      .contact-overlay label {
        padding-left: 0px; } }
  .contact-overlay .submit-btn-wrapper {
    padding: 50px 0 50px 0; }
  .contact-overlay .callback-wrapper {
    padding-top: 30px; }
  .contact-overlay .gender-wrapper {
    padding-bottom: 20px; }
  .contact-overlay .decoration {
    position: absolute;
    right: 15px;
    bottom: -120px;
    animation: animateOnigamiFlying 3s ease-in-out infinite 0s; }
    @media screen and (max-width: 47.9375em) {
      .contact-overlay .decoration {
        display: none; } }

.contact-success {
  display: none;
  opacity: 0; }
  .contact-success.animate {
    display: block;
    animation: animateInOverlay 0.75s ease-in-out forwards; }

.contact-form-wrapper.animate {
  animation: animateOutOverlay 0.4s ease-in-out forwards; }
  .contact-form-wrapper.animate.hide {
    display: none; }

.origami-contact-wrapper {
  position: relative; }

@keyframes animateOnigamiTorso {
  0% {
    opacity: 0;
    bottom: -160px; }
  80% {
    bottom: -10px;
    transform: rotate(-5deg);
    opacity: 1;
    filter: blur(0px); }
  100% {
    bottom: -30px;
    opacity: 1;
    transform: rotate(0deg);
    filter: blur(0px); } }

@keyframes animateOnigamiHead {
  30% {
    transform: rotate(5deg);
    left: 5px; }
  50% {
    transform: rotate(10deg);
    left: 10px; }
  100% {
    transform: rotate(0deg);
    left: 0px; } }

@keyframes animateOnigamiFlying {
  0% {
    transform: translate(0px, 0px); }
  50% {
    transform: translate(8px, 5px); }
  100% {
    transform: translate(0px, 0px); } }

@media screen and (max-width: 47.9375em) {
  @keyframes animateOnigamiTorso {
    0% {
      opacity: 0;
      bottom: -160px; }
    80% {
      bottom: -120px;
      transform: rotate(-5deg);
      opacity: 1;
      filter: blur(0px); }
    100% {
      bottom: -135px;
      opacity: 1;
      transform: rotate(0deg);
      filter: blur(0px); } } }

.fullscreen {
  min-height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }
  .fullscreen .top-content-section {
    width: 100%; }
  .fullscreen .middle-content-section {
    height: 100vh;
    width: 100%;
    min-height: 100%;
    text-align: center;
    position: relative;
    color: #b4ccd8; }
    .fullscreen .middle-content-section p {
      padding-top: 20px;
      padding-bottom: 20px; }
      @media screen and (max-width: 47.9375em) {
        .fullscreen .middle-content-section p {
          opacity: 1; } }
  .fullscreen .bottom-content-section {
    padding: 1rem;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    text-align: center; }
    @media screen and (max-width: 74.9375em) {
      .fullscreen .bottom-content-section {
        bottom: 15px; } }
  .fullscreen .animation-content-blur-start {
    position: relative;
    width: 100%; }
    .fullscreen .animation-content-blur-start > div {
      position: absolute;
      top: 0px;
      width: 100%; }
      .fullscreen .animation-content-blur-start > div.last {
        position: relative; }
  .fullscreen .onigramm {
    position: absolute;
    bottom: -160px;
    right: -50px;
    width: 320px;
    height: 328px;
    opacity: 0;
    filter: blur(10px);
    transform: rotate(50deg); }
    .fullscreen .onigramm .onig-head {
      background: url("../img/2x/origami_bird_part3_2x.png") no-repeat;
      background-size: 100%;
      width: 119px;
      height: 108px;
      position: absolute;
      top: 36px;
      z-index: 1;
      left: 44px;
      transform: rotate(45deg); }
    .fullscreen .onigramm .onig-torse {
      background: url("../img/2x/origami_bird_part2_2x.png") no-repeat;
      background-size: 100%;
      width: 245px;
      height: 288px;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 2; }
    .fullscreen .onigramm .onig-bottom {
      background: url("../img/2x/origami_bird_part1_2x.png") no-repeat;
      background-size: 100%;
      width: 77px;
      height: 180px;
      position: absolute;
      top: 144px;
      z-index: 3;
      transition: all 1.5s ease-in-out 2s;
      right: 28px;
      transform: rotate(-15deg); }
    .fullscreen .onigramm.animate {
      animation: animateOnigamiTorso 3s ease-in-out forwards, animateOnigamiFlying 3s ease-in-out infinite 3s; }
      .fullscreen .onigramm.animate .onig-head {
        animation: animateOnigamiHead 3s ease-in-out forwards 1.5s; }
      .fullscreen .onigramm.animate .onig-bottom {
        right: 52px;
        transform: rotate(0deg); }
    @media screen and (max-width: 47.9375em) {
      .fullscreen .onigramm {
        right: -90px;
        bottom: -135px; } }
  .fullscreen .fulls-c-1, .fullscreen .fulls-c-2 {
    max-width: 750px;
    width: 100%;
    margin: auto; }
    .fullscreen .fulls-c-1 .animation-copy, .fullscreen .fulls-c-2 .animation-copy {
      max-width: 490px;
      width: 100%;
      margin: auto; }
  @media screen and (max-width: 47.9375em) {
    .fullscreen .animation-content-blur-2 {
      opacity: 0; } }
  @media screen and (max-width: 39.9375em) {
    .fullscreen .animation-content-blur-2 {
      opacity: 1; } }
  @media screen and (max-width: 47.9375em) {
    .fullscreen .animation-content-blur-3 {
      opacity: 0; } }
  @media screen and (max-width: 39.9375em) {
    .fullscreen .animation-content-blur-3 {
      opacity: 1; } }
  @media screen and (max-width: 39.9375em) {
    .fullscreen {
      height: 100%;
      min-height: 780px; }
      .fullscreen .align-self-middle {
        align-self: inherit;
        padding-top: 30px; }
      .fullscreen .fulls-c-1, .fullscreen .fulls-c-2 {
        width: 100%;
        float: left; }
        .fullscreen .fulls-c-1 .animation-copy, .fullscreen .fulls-c-2 .animation-copy {
          width: 100%;
          margin: auto; }
      .fullscreen .onigramm {
        display: none;
        width: 160px;
        height: 164px;
        bottom: -80px;
        right: -25px; }
        .fullscreen .onigramm .onig-head {
          width: 59.5px;
          height: 54px;
          top: 18px;
          left: 22px; }
        .fullscreen .onigramm .onig-torse {
          width: 122.5px;
          height: 144px; }
        .fullscreen .onigramm .onig-bottom {
          width: 38.5px;
          height: 90px;
          top: 72px;
          right: 14px; }
        .fullscreen .onigramm.animate .onig-bottom {
          right: 26px;
          transform: rotate(0deg); }
      .fullscreen .full_origami_mobile {
        /*            width: 280px;
            height: 250px;
            background: url(../img/origami/full_origami_mobile.png) no-repeat 0px 0px;*/
        background-image: url("../img/origami/full_origami_mobile.png");
        background-repeat: no-repeat;
        width: 280px;
        height: 250px;
        display: inline-block;
        background-size: 100%;
        margin: 0px auto 40px auto;
        clear: both; } }
    @media screen and (max-width: 39.9375em) and (-webkit-min-device-pixel-ratio: 2), screen and (max-width: 39.9375em) and (min-resolution: 192dpi) {
      .fullscreen .full_origami_mobile {
        background-image: url("../img/2x/origami/full_origami_mobile_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  @media screen and (max-width: 39.9375em) {
      .fullscreen .bottom-content-section {
        position: relative;
        margin-left: -50px;
        left: 50%;
        top: inherit;
        bottom: inherit;
        width: 100px; }
      .fullscreen .middle-content-section {
        height: 100%; }
      .fullscreen .row {
        display: block; } }

.content.subpage h1, .content.subpage .h1 {
  font-size: 2.5rem; }

.model-origami .model .line-main {
  color: #667f8b;
  font-family: 'Roboto-Medium'; }

.model-origami .model .line-sub {
  color: #7d756b; }
  .model-origami .model .line-sub.light {
    color: #fff; }

.model-origami .key {
  width: 300px;
  margin: 50px auto 0; }
  @media screen and (min-width: 48em) {
    .model-origami .key {
      display: none; } }
  .model-origami .key .key-item {
    position: relative;
    margin-bottom: 20px; }
    .model-origami .key .key-item:last-child {
      margin-bottom: 0; }
    .model-origami .key .key-item .icon-key {
      position: absolute;
      top: 0;
      left: 7px; }
    .model-origami .key .key-item .key-text {
      width: 248px;
      margin-left: 52px;
      text-align: left; }

.model-origami .annotation {
  margin-top: 55px; }

.arrow-column {
  position: relative; }

.arrow-border {
  position: absolute;
  width: calc(100% - 2 * 0.9375rem); }
  .arrow-border::after {
    content: '';
    position: absolute;
    background-image: url("../img/arrowhead.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top left;
    width: 10px;
    height: 12px; }
  .arrow-border.top {
    top: 0;
    border-top: 2px solid #7d756b;
    padding-top: 15px;
    margin-top: 30px; }
    .arrow-border.top::after {
      top: -7px; }
  .arrow-border.bottom {
    bottom: 0;
    border-bottom: 2px solid #7d756b;
    padding-bottom: 15px;
    margin-bottom: 30px; }
    .arrow-border.bottom::after {
      bottom: -7px; }
  .arrow-border.left {
    text-align: right; }
    .arrow-border.left::after {
      left: -5px;
      transform: scaleX(-1); }
  .arrow-border.right {
    text-align: left; }
    .arrow-border.right::after {
      right: -5px; }

.photo-circle {
  border: 6px solid #f0f5f7;
  border-radius: 50%; }
  .photo-circle.carsten {
    max-width: 100%;
    background-image: url("../img/berater/circle-carsten.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 395px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .photo-circle.carsten {
        background-image: url("../img/2x/berater/circle-carsten_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
    .photo-circle.carsten::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%; }
  .photo-circle.marc {
    max-width: 100%;
    background-image: url("../img/berater/circle-marc.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 395px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .photo-circle.marc {
        background-image: url("../img/2x/berater/circle-marc_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
    .photo-circle.marc::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%; }
  .photo-circle.melanie {
    max-width: 100%;
    background-image: url("../img/berater/circle-melanie.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 395px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .photo-circle.melanie {
        background-image: url("../img/2x/berater/circle-melanie_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
    .photo-circle.melanie::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%; }

.button {
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.1em; }
  .button.dark-blue {
    background-color: #667f8b; }
  .button.white {
    background-color: #fff;
    color: #7d756b; }
  .button.medium {
    padding: 20px 30px; }
  .button:hover, .button:focus {
    background-color: #b4ccd8; }
  .button.animationComplete {
    transition: all 0.25s ease-in-out !important; }

.stage-background {
  position: relative; }

.stage-background-circles {
  position: absolute;
  top: 0;
  right: 20px;
  background-image: url("../img/circles-berater.png");
  background-repeat: no-repeat;
  width: 322px;
  height: 441px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .stage-background-circles {
      background-image: url("../img/2x/circles-berater_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }

.nav-main ul.menu {
  display: inline-block;
  float: right; }
  .nav-main ul.menu li {
    display: inline-block; }
    .nav-main ul.menu li a {
      text-transform: uppercase;
      font-family: 'Roboto-Medium';
      font-size: 12px;
      color: #667f8b;
      letter-spacing: 0.1em;
      transition: all 0.25s ease-in-out; }
      .nav-main ul.menu li a:hover {
        color: #b4ccd8; }
      .nav-main ul.menu li a.active {
        position: relative;
        color: #b4ccd8; }
        .nav-main ul.menu li a.active::after {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% - 2rem);
          left: 1rem;
          bottom: 0;
          border-bottom: 2px solid #b4ccd8; }

.nav-bar.wrapper-fullwidth .row:first-child {
  padding-top: 10px; }

.nav-bar .hamburger {
  z-index: 501; }
  .nav-bar .hamburger.is-active {
    position: fixed;
    top: 10px;
    right: 10px; }

.nav-bar .hamburger-box {
  width: 20px;
  height: 14px; }

.nav-bar .hamburger-inner:after,
.nav-bar .hamburger-inner:before,
.nav-bar .hamburger-inner {
  background-color: #667f8b;
  width: 20px;
  height: 2px;
  border-radius: 0px; }

.nav-bar .hamburger-inner:before {
  top: -6px; }

.nav-bar .hamburger-inner:after {
  bottom: -6px; }

.on-fullscreen-navi {
  z-index: 501; }

.fullscreen-navi {
  position: fixed;
  top: 0;
  right: -100%;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: #b4ccd8;
  z-index: 500;
  transition: all 0.4s ease-in-out;
  filter: blur(40px); }
  .fullscreen-navi.active {
    right: 0%;
    opacity: 1;
    filter: blur(0px); }
  .fullscreen-navi .row {
    height: 100%; }
  .fullscreen-navi ul {
    width: 100%;
    text-align: center;
    margin: 0px; }
    .fullscreen-navi ul li {
      width: 100%;
      text-align: center;
      display: inline-block; }
      .fullscreen-navi ul li a {
        font-size: 24px;
        line-height: 60px;
        letter-spacing: 0.2 rem;
        color: #667f8b;
        text-transform: uppercase;
        font-family: 'Roboto-Medium';
        transition: all 0.25s ease-in-out; }
        .fullscreen-navi ul li a:hover {
          color: #d2e0e8; }
        .fullscreen-navi ul li a.active {
          position: relative;
          color: #d2e0e8; }
          .fullscreen-navi ul li a.active::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -10px;
            border-bottom: 2px solid #d2e0e8; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

.leistungen-editorial {
  margin-bottom: 30px; }

.leistungen-box .h3 {
  margin-bottom: 15px; }

.leistungen-box .h6 {
  margin-bottom: 10px;
  transition: all .25s ease-in-out 0.5s;
  opacity: 0;
  top: 20px;
  position: relative; }

.leistungen-box .leistung-text {
  min-height: 60px;
  transition: all .25s ease-in-out 0.75s;
  opacity: 0;
  top: 20px;
  position: relative; }

.leistungen-box .text-link {
  opacity: 0;
  top: 20px;
  position: relative; }

.leistungen-box.animate .leistungen-icn-box-1 {
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  perspective: 1500;
  opacity: 1; }

.leistungen-box.animate .leistungen-icn-box-2 {
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  perspective: 1500;
  opacity: 1; }

.leistungen-box.animate .leistungen-icn-box-3 {
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  perspective: 1500;
  opacity: 1; }

.leistungen-box.animate .leistung-text, .leistungen-box.animate .h6, .leistungen-box.animate .text-link {
  opacity: 1;
  top: 0px; }

.leistungen-box:nth-child(1) .h6 {
  transition: all 0.25s ease-in-out 1.25s; }

.leistungen-box:nth-child(1) .leistung-text {
  transition: all 0.25s ease-in-out 1.25s; }

.leistungen-box:nth-child(1) .text-link {
  transition: all 1s ease-in-out 1.25s; }

.leistungen-box:nth-child(2) .h6 {
  transition: all 0.25s ease-in-out 1.5s; }

.leistungen-box:nth-child(2) .leistung-text {
  transition: all 0.25s ease-in-out 1.5s; }

.leistungen-box:nth-child(2) .text-link {
  transition: all 1s ease-in-out 1.5s; }

.leistungen-box:nth-child(3) .h6 {
  transition: all 0.25s ease-in-out 1.75s; }

.leistungen-box:nth-child(3) .leistung-text {
  transition: all 0.25s ease-in-out 1.75s; }

.leistungen-box:nth-child(3) .text-link {
  transition: all 1s ease-in-out 1.75s; }

.leistungen-icn-box-1 {
  background-image: url("../img/icn-leistungen-1.png");
  background-repeat: no-repeat;
  width: 158px;
  height: 158px;
  display: inline-block;
  display: block;
  margin: 0px auto 15px auto;
  transform: rotateX(90deg);
  transition: all .5s ease-in-out 0.5s;
  opacity: 0; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .leistungen-icn-box-1 {
      background-image: url("../img/2x/icn-leistungen-1_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  @media screen and (max-width: 39.9375em) {
    .leistungen-icn-box-1 {
      transition: all .5s ease-in-out 0.5s; } }

.leistungen-icn-box-2 {
  background-image: url("../img/icn-leistungen-2.png");
  background-repeat: no-repeat;
  width: 158px;
  height: 158px;
  display: inline-block;
  display: block;
  margin: 0px auto 15px auto;
  transform: rotateY(90deg);
  transition: all .5s ease-in-out 0.75s;
  opacity: 0; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .leistungen-icn-box-2 {
      background-image: url("../img/2x/icn-leistungen-2_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  @media screen and (max-width: 39.9375em) {
    .leistungen-icn-box-2 {
      transition: all .5s ease-in-out 0.5s; } }

.leistungen-icn-box-3 {
  background-image: url("../img/icn-leistungen-3.png");
  background-repeat: no-repeat;
  width: 158px;
  height: 158px;
  display: inline-block;
  display: block;
  margin: 0px auto 15px auto;
  transform: rotateY(-90deg);
  transition: all .5s ease-in-out 1s;
  opacity: 0; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .leistungen-icn-box-3 {
      background-image: url("../img/2x/icn-leistungen-3_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  @media screen and (max-width: 39.9375em) {
    .leistungen-icn-box-3 {
      transition: all .5s ease-in-out 0.5s; } }

@media screen and (max-width: 74.9375em) {
  .leistung-text {
    min-height: inherit; }
  .leistungen-box .text-link {
    float: left;
    margin-bottom: 40px; } }

.content.berater .h3 {
  opacity: 0;
  transform: translateY(0px);
  transition: all 0.5s ease-in-out 0.2s; }

.content.berater h6 {
  opacity: 0;
  transform: translateY(0px);
  transition: all 0.5s ease-in-out 0.3s; }

.content.berater p {
  opacity: 0;
  transform: translateY(0px);
  transition: all 0.5s ease-in-out 0.4s; }

.content.berater a {
  opacity: 0;
  transform: translateY(0px);
  transition: all 0.5s ease-in-out 0.5s; }

.content.berater .images-berater .front,
.content.berater .images-berater .back {
  opacity: 0;
  transform: translateY(15px);
  transition: all 0.5s ease-in-out 0.6s; }

.content.berater.animate .h3 {
  opacity: 1;
  transform: translateY(-15px); }

.content.berater.animate h6 {
  opacity: 1;
  transform: translateY(-15px); }

.content.berater.animate p {
  opacity: 1;
  transform: translateY(-15px); }

.content.berater.animate a {
  opacity: 1;
  transform: translateY(-15px); }

.content.berater.animate .images-berater .front,
.content.berater.animate .images-berater .back {
  opacity: 1;
  transform: translateY(0px); }

.kontakt-section {
  padding-top: 110px;
  padding-bottom: 105px; }
  .kontakt-section .h3 {
    margin-bottom: 25px;
    opacity: 0;
    top: 10px;
    position: relative;
    transition: all 0.45s ease-in-out 1.25s; }
  .kontakt-section .button {
    opacity: 0;
    top: 10px;
    position: relative;
    transition: all 0.45s ease-in-out 1.5s; }
  @media screen and (max-width: 47.9375em) {
    .kontakt-section {
      padding-top: 50px;
      padding-bottom: 45px; } }
  .kontakt-section.animate .h3, .kontakt-section.animate .button {
    opacity: 1;
    top: 0px; }

.referenzen {
  /*    max-height: 585px;*/
  background: url(../img/pattern_onblue.png) no-repeat;
  background-color: #b4ccd8;
  background-position: 120% -65px;
  padding-top: 0px;
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .referenzen {
      max-height: inherit; } }
  .referenzen .row {
    position: relative; }
  .referenzen .head-referenzen {
    margin-top: 45px; }
    @media screen and (max-width: 47.9375em) {
      .referenzen .head-referenzen .h2 {
        margin-bottom: 0rem; } }
  .referenzen .bg-circle {
    background: url(../img/circles_white_trans.png) no-repeat -160px 80px;
    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0px;
    position: absolute;
    z-index: 1; }
    @media screen and (max-width: 74.9375em) {
      .referenzen .bg-circle {
        display: none; } }
  .referenzen .row {
    z-index: 2; }

.headline-only {
  background: url(../img/pattern_onblue.png) no-repeat;
  background-color: #b4ccd8;
  background-position: 120% -65px; }
  .headline-only .h1, .headline-only .h2, .headline-only .h3, .headline-only .h4, .headline-only .h5, .headline-only .h6 {
    margin-bottom: 0; }

.content.strategy, .content.engagement {
  padding-top: 60px;
  margin-bottom: 45px;
  background: url(../img/pattern_onblue.png) no-repeat;
  background-color: #b4ccd8;
  background-position: 120% -65px; }

.slider-wrapper {
  margin: auto;
  position: relative; }

.slider-referenzen-wrapper .slider-referenzen {
  transform: translate(0px, 20px);
  transition: all .25s ease-in-out 1s;
  opacity: 0;
  height: max-content; }
  .slider-referenzen-wrapper .slider-referenzen .slide-ref-content.h3 {
    text-transform: uppercase; }
  .slider-referenzen-wrapper .slider-referenzen #slide-ref-img-1 {
    background-image: url("../img/referenzen/slide-ref-1.png");
    background-repeat: no-repeat;
    width: 152px;
    height: 110px;
    display: inline-block;
    background-position: center center;
    width: 100%;
    height: 110px;
    display: inline-block;
    margin: 10px auto 0px; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slider-referenzen-wrapper .slider-referenzen #slide-ref-img-1 {
        background-image: url("../img/2x/referenzen/slide-ref-1_2x.png");
        background-size: 152px 110px;
        background-repeat: no-repeat; } }
  .slider-referenzen-wrapper .slider-referenzen #slide-ref-img-2 {
    background-image: url("../img/referenzen/slide-ref-2.png");
    background-repeat: no-repeat;
    width: 133px;
    height: 144px;
    display: inline-block;
    background-position: center center;
    width: 100%;
    height: 144px;
    display: inline-block;
    margin: 10px auto 0px; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slider-referenzen-wrapper .slider-referenzen #slide-ref-img-2 {
        background-image: url("../img/2x/referenzen/slide-ref-2_2x.png");
        background-size: 133px 144px;
        background-repeat: no-repeat; } }
  .slider-referenzen-wrapper .slider-referenzen #slide-ref-img-3 {
    background-image: url("../img/referenzen/slide-ref-3.png");
    background-repeat: no-repeat;
    width: 150px;
    height: 92px;
    display: inline-block;
    background-position: center center;
    width: 100%;
    height: 93px;
    display: inline-block;
    margin: 10px auto 0px; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slider-referenzen-wrapper .slider-referenzen #slide-ref-img-3 {
        background-image: url("../img/2x/referenzen/slide-ref-3_2x.png");
        background-size: 150px 92px;
        background-repeat: no-repeat; } }
  .slider-referenzen-wrapper .slider-referenzen #slide-ref-img-4 {
    background-image: url("../img/referenzen/slide-ref-4.png");
    background-repeat: no-repeat;
    width: 160px;
    height: 96px;
    display: inline-block;
    background-position: center center;
    width: 100%;
    height: 96px;
    display: inline-block;
    margin: 10px auto 0px; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slider-referenzen-wrapper .slider-referenzen #slide-ref-img-4 {
        background-image: url("../img/2x/referenzen/slide-ref-4_2x.png");
        background-size: 160px 96px;
        background-repeat: no-repeat; } }
  .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo {
    min-width: 150px;
    min-height: 120px;
    list-style: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 100%;
    margin: auto; }
    @media screen and (min-width: 40em) {
      .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo {
        display: none; } }
    .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo.logo-1:after {
      background-image: url("../img/referenzen/referenz_cronimet.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
      background-position: center 0px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo.logo-1:after {
          background-image: url("../img/2x/referenzen/referenz_cronimet_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo.logo-2:after {
      background-image: url("../img/referenzen/referenz_eurofins.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
      background-position: center 0px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo.logo-2:after {
          background-image: url("../img/2x/referenzen/referenz_eurofins_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo.logo-3:after {
      background-image: url("../img/referenzen/referenz_valovisbank.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
      background-position: center 0px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo.logo-3:after {
          background-image: url("../img/2x/referenzen/referenz_valovisbank_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo.logo-4:after {
      background-image: url("../img/referenzen/referenz_stonehage.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
      background-position: center 0px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo.logo-4:after {
          background-image: url("../img/2x/referenzen/referenz_stonehage_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-wrapper .slider-referenzen .slide-ref-logo:after {
      content: ' ';
      font-size: 0px;
      height: 100px;
      width: 100px;
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      left: 50%;
      margin-left: -50px; }
  .slider-referenzen-wrapper .slider-referenzen .slide-ref-desc {
    padding-top: 25px;
    text-transform: uppercase; }
  .slider-referenzen-wrapper .slider-referenzen .slick-dots {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 10px 0 0 0; }
    .slider-referenzen-wrapper .slider-referenzen .slick-dots li {
      border-radius: 20px;
      width: 20px;
      height: 20px;
      margin: 10px;
      background-color: #d2e0e8;
      list-style: none;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      transition: all 0.25s ease-in-out; }
      .slider-referenzen-wrapper .slider-referenzen .slick-dots li:hover, .slider-referenzen-wrapper .slider-referenzen .slick-dots li:focus, .slider-referenzen-wrapper .slider-referenzen .slick-dots li.slick-active {
        background-color: #667f8b; }
      .slider-referenzen-wrapper .slider-referenzen .slick-dots li button {
        font-size: 0;
        display: none; }

.slider-referenzen-wrapper .slider-arrows {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%; }
  .slider-referenzen-wrapper .slider-arrows .left-arrow {
    float: left; }
  .slider-referenzen-wrapper .slider-arrows .right-arrow {
    float: right; }
  @media screen and (min-width: 40em) {
    .slider-referenzen-wrapper .slider-arrows {
      display: none; } }
  @media screen and (max-width: 47.9375em) {
    .slider-referenzen-wrapper .slider-arrows .left:hover, .slider-referenzen-wrapper .slider-arrows .left:active, .slider-referenzen-wrapper .slider-arrows .right:hover, .slider-referenzen-wrapper .slider-arrows .right:active {
      border: 2px solid #d2e0e8; }
      .slider-referenzen-wrapper .slider-arrows .left:hover:after, .slider-referenzen-wrapper .slider-arrows .left:active:after, .slider-referenzen-wrapper .slider-arrows .right:hover:after, .slider-referenzen-wrapper .slider-arrows .right:active:after {
        border-top: 2px solid #d2e0e8;
        border-right: 2px solid #d2e0e8; } }

.slider-referenzen-wrapper .slick-list.draggable {
  /*        max-height: 385px;*/ }
  @media screen and (max-width: 47.9375em) {
    .slider-referenzen-wrapper .slick-list.draggable {
      max-height: inherit; } }

.slider-referenzen-wrapper .slick-slide {
  height: inherit; }

.slider-referenzen-navi {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  transition: all 0.25s ease-in-out 1.25s;
  transform: translate(70px, 0px);
  opacity: 0; }
  .slider-referenzen-navi ul {
    margin: 0px; }
  .slider-referenzen-navi .slick-ref-navi-links {
    min-width: 150px;
    min-height: 150px;
    list-style: none;
    border-bottom: solid 2px #d2e0e8;
    display: inline-block;
    position: relative;
    cursor: pointer; }
    .slider-referenzen-navi .slick-ref-navi-links#slide-1 span {
      background-image: url("../img/referenzen/referenz_cronimet.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-navi .slick-ref-navi-links#slide-1 span {
          background-image: url("../img/2x/referenzen/referenz_cronimet_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-navi .slick-ref-navi-links#slide-1.active span, .slider-referenzen-navi .slick-ref-navi-links#slide-1 span:after {
      background-image: url("../img/referenzen/referenz_cronimet.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
      background-position: center -100px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-navi .slick-ref-navi-links#slide-1.active span, .slider-referenzen-navi .slick-ref-navi-links#slide-1 span:after {
          background-image: url("../img/2x/referenzen/referenz_cronimet_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-navi .slick-ref-navi-links#slide-2 span {
      background-image: url("../img/referenzen/referenz_eurofins.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-navi .slick-ref-navi-links#slide-2 span {
          background-image: url("../img/2x/referenzen/referenz_eurofins_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-navi .slick-ref-navi-links#slide-2.active span, .slider-referenzen-navi .slick-ref-navi-links#slide-2 span:after {
      background-image: url("../img/referenzen/referenz_eurofins.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
      background-position: center -100px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-navi .slick-ref-navi-links#slide-2.active span, .slider-referenzen-navi .slick-ref-navi-links#slide-2 span:after {
          background-image: url("../img/2x/referenzen/referenz_eurofins_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-navi .slick-ref-navi-links#slide-3 span {
      background-image: url("../img/referenzen/referenz_valovisbank.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-navi .slick-ref-navi-links#slide-3 span {
          background-image: url("../img/2x/referenzen/referenz_valovisbank_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-navi .slick-ref-navi-links#slide-3.active span, .slider-referenzen-navi .slick-ref-navi-links#slide-3 span:after {
      background-image: url("../img/referenzen/referenz_valovisbank.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
      background-position: center -100px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-navi .slick-ref-navi-links#slide-3.active span, .slider-referenzen-navi .slick-ref-navi-links#slide-3 span:after {
          background-image: url("../img/2x/referenzen/referenz_valovisbank_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-navi .slick-ref-navi-links#slide-4 span {
      background-image: url("../img/referenzen/referenz_stonehage.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-navi .slick-ref-navi-links#slide-4 span {
          background-image: url("../img/2x/referenzen/referenz_stonehage_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-navi .slick-ref-navi-links#slide-4.active span, .slider-referenzen-navi .slick-ref-navi-links#slide-4 span:after {
      background-image: url("../img/referenzen/referenz_stonehage.png");
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
      background-position: center -100px; }
      @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .slider-referenzen-navi .slick-ref-navi-links#slide-4.active span, .slider-referenzen-navi .slick-ref-navi-links#slide-4 span:after {
          background-image: url("../img/2x/referenzen/referenz_stonehage_2x.png");
          background-size: 100px 200px;
          background-repeat: no-repeat; } }
    .slider-referenzen-navi .slick-ref-navi-links span {
      font-size: 0px;
      height: 100px;
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      left: 50%;
      margin-left: -50px; }
      .slider-referenzen-navi .slick-ref-navi-links span:after {
        transition: all 0.25s ease-in-out;
        opacity: 0;
        content: '';
        left: 0px;
        width: 100%;
        height: 100%;
        display: inline-block;
        position: absolute;
        top: 0px;
        z-index: 10; }
    .slider-referenzen-navi .slick-ref-navi-links:hover span:after {
      opacity: 1; }
  .slider-referenzen-navi .slick-current .slick-ref-navi-links#slide-1 span {
    background-position: center -100px; }
  .slider-referenzen-navi .slick-current .slick-ref-navi-links#slide-2 span {
    background-position: center -100px; }
  .slider-referenzen-navi .slick-current .slick-ref-navi-links#slide-3 span {
    background-position: center -100px; }
  .slider-referenzen-navi .slick-current .slick-ref-navi-links#slide-4 span {
    background-position: center -100px; }
  .slider-referenzen-navi .slick-slide {
    float: inherit; }
  .slider-referenzen-navi .slick-track {
    width: auto !important;
    transform: translate3d(0px, 0px, 0px) !important; }

/*.slider-berater-wrapper{

    transition: all 0.25s ease-in-out 1.25s;
    transform: translate(0px, 20px);
    opacity: 0;

    .slider-arrows {
        top: 80px;
    }

    .slick-dots {
        top: 60px;
    }

    .slide{
        .left{
            h6{
                margin-bottom: 0px;
            }
            .h3{
                line-height: 28px;
                padding-bottom: 15px;
                text-transform: uppercase;
                font-size: 18px;

            }
            p{
                &.headerlin{
                    font-family: 'Roboto-Medium';
                    font-weight: bold;
                    color:$medium-blue;
                    margin-bottom: 7px;
                }
                margin-bottom: 0px;
                line-height: 24px;
            }
            .skill-box{
                padding-top: 45px;
            }

        }
        .right{
            position: relative;
            min-height: 600px;
            #slide-ber-img-1{
                background: url(../img/berater/berater-slide-1.png) no-repeat right bottom;
                width: 631px;
                height: 503px;
                position: absolute;
                //bottom: 0px;
                right: 12px;
                display: inline-block;
            }
            #slide-ber-img-2{
                background: url(../img/berater/berater-slide-2.png) no-repeat right bottom;
                width: 631px;
                height: 503px;
                position: absolute;
                //bottom: 0px;
                right: 12px;
                display: inline-block;
            }

            div[id^="slide-ber-img-"]{
                bottom: -20px;
                opacity: 0;
                transition: all 0.25s ease-in-out 0.45s;
            }

            @include breakpoint(medium down) {
                min-height: 340px;
                #slide-ber-img-1, #slide-ber-img-2{
                    background-size: 100%;
                    width: 95%;
                    height:100%;
                }
            }
            @include breakpoint(small down) {
                #slide-ber-img-1, #slide-ber-img-2{
                    background-size: auto 80% ;
                    background-position: center bottom;
                }
            }
        }
        .text-link{
            margin-bottom: 15px;
        }
        .row{
            height: 100%;
        }
    }
    .slick-slide{
        height: inherit;
    }
    .skill-box{
        opacity: 0;
        position: relative;
        top:-5px;
        &:nth-child(1){transition: all 0.25s ease-in-out 0.45s}
        &:nth-child(2){transition: all 0.25s ease-in-out 0.6s}
        &:nth-child(3){transition: all 0.25s ease-in-out 0.75s;}

        .text-link{
            margin-bottom: 60px;
        }
    }

}*/
.animate {
  /*    .slider-berater-wrapper{
        transform: translate(0px, 0px);
        opacity: 1;
    }*/ }
  .animate .slick-active .skill-box {
    opacity: 1;
    top: 0px; }
  .animate .slick-active div[id^="slide-ber-img-"] {
    bottom: 0px !important;
    opacity: 1 !important; }
  .animate.referenzen .slider-referenzen {
    transform: translate(0px, 0px);
    opacity: 1; }
  .animate.referenzen .slider-referenzen-navi {
    transform: translate(0px, 0px);
    opacity: 1; }

.slider-leistungen-personal-wrapper .slick-slide {
  height: auto; }

.slider-leistungen-personal-wrapper .slider-arrows {
  top: 63px; }

.slider-leistungen-personal-wrapper .slick-dots {
  top: 43px; }

.slider-leistungen-personal-wrapper .slide .h3, .slider-leistungen-personal-wrapper .slide .h4 {
  line-height: 28px;
  padding-bottom: 0px;
  text-transform: uppercase; }

@media screen and (max-width: 63.9375em) {
  .slider-leistungen-personal-wrapper .slider-arrows {
    top: 91px; }
  .slider-leistungen-personal-wrapper .slick-dots {
    top: 71px; }
  .slider-leistungen-personal-wrapper .slide-headline-container {
    position: relative;
    height: 71px; }
    .slider-leistungen-personal-wrapper .slide-headline-container .slide-header {
      position: absolute;
      bottom: 0; }
  .slider-leistungen-personal-wrapper .h3 {
    margin-bottom: 0; } }

@media screen and (max-width: 47.9375em) {
  .slider-leistungen-personal-wrapper .slider-arrows {
    top: 120px; }
  .slider-leistungen-personal-wrapper .slick-dots {
    top: 100px; }
  .slider-leistungen-personal-wrapper .slide-headline-container {
    height: 100px; } }

.slider-top-arrows {
  position: relative; }
  .slider-top-arrows .slider-arrows-container {
    position: relative; }
  .slider-top-arrows .slider-arrows {
    z-index: 1000;
    position: absolute;
    width: 100%;
    text-align: center; }
    .slider-top-arrows .slider-arrows a {
      padding: 0px 5px 0px; }

.slide-indicator-line .slick-dots {
  position: absolute;
  margin: 0px;
  text-align: center;
  display: flex;
  width: 100%; }
  .slide-indicator-line .slick-dots li {
    display: inline-block;
    text-align: center;
    flex: 1 1 auto;
    font-size: 0px;
    height: 2px;
    background-color: #d2e0e8;
    transition: background-color 0.25s ease-in-out; }
    .slide-indicator-line .slick-dots li.slick-active {
      background-color: #667f8b; }
  @media screen and (min-width: 40em) {
    .slide-indicator-line .slick-dots.dots-10-in-12 {
      width: calc(83.33333% - 0.46875rem);
      margin-left: 8.66666%; } }

.slide-content {
  margin-top: 100px;
  margin-bottom: 40px; }
  .slide-content.bullet-origami .origami {
    margin-bottom: 30px; }
    @media screen and (min-width: 64em) {
      .slide-content.bullet-origami .origami {
        margin-bottom: 0; } }

.slick-slider .slider-content-blur-fade-in {
  transition: all .25s ease-in-out 0.5s;
  filter: blur(15px);
  opacity: 0; }

.slick-slider .slider-content-move-in {
  transition: all .25s ease-in-out 0.75s;
  transform: translateY(-15px);
  opacity: 0; }

.slick-slider .slider-image-rotate-in {
  transition: all .25s ease-in-out 0.75s;
  transform: rotateY(90deg) rotateX(90deg);
  opacity: 0; }

.slick-slider .slick-active .slider-content-blur-fade-in {
  filter: blur(0px);
  opacity: 1; }

.slick-slider .slick-active .slider-content-move-in {
  transform: translateY(0px);
  opacity: 1; }

.slick-slider .slick-active .slider-image-rotate-in {
  transform: rotateY(0deg) rotateX(0deg);
  transform-style: preserve-3d;
  perspective: 1500;
  opacity: 1; }

@media screen and (max-width: 39.9375em) {
  .slider-referenzen-navi {
    display: none; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@media screen and (min-width: 40em) {
  .accordion-content .content.c-bg-light-gray {
    padding-top: 50px !important; } }

.accordion-title .h3 {
  margin-bottom: 0; }

.accordion-title-box {
  position: relative;
  border-bottom: 2px solid #d2e0e8;
  padding-bottom: 10px;
  padding-right: 29px; }
  .accordion-title-box::before {
    content: '';
    position: absolute;
    bottom: 24px;
    right: 0;
    transition: transform 0.3s linear;
    background-image: url("../img/accordion-arrow.png");
    background-repeat: no-repeat;
    width: 19px;
    height: 10px;
    display: inline-block; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .accordion-title-box::before {
        background-image: url("../img/2x/accordion-arrow_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }

.accordion-header-photo {
  position: relative; }
  .accordion-header-photo .photo-container {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    opacity: 0;
    transform: translate(0px, 20px);
    transition: all 1s ease-in-out; }
    .accordion-header-photo .photo-container.animate {
      opacity: 1;
      transform: translate(0px, 0px); }
    @media screen and (min-width: 40em) {
      .accordion-header-photo .photo-container {
        height: 170px; } }
  @media screen and (min-width: 40em) {
    .accordion-header-photo.left .photo-circle, .accordion-header-photo.right .photo-circle {
      position: absolute;
      bottom: -40%;
      margin-bottom: -10%; } }
  @media screen and (min-width: 40em) {
    .accordion-header-photo.left .photo-circle {
      left: 0; } }
  @media screen and (min-width: 64em) {
    .accordion-header-photo.left .photo-circle {
      right: auto;
      left: calc(50% - 197.5px); } }
  @media screen and (min-width: 40em) {
    .accordion-header-photo.right .photo-circle {
      right: 0; } }
  @media screen and (min-width: 64em) {
    .accordion-header-photo.right .photo-circle {
      right: auto;
      left: calc(50% - 197.5px); } }

.accordion-item:nth-child(2) .accordion-header-photo .photo-container {
  transition: all 1s ease-in-out 0.75s; }

.accordion-item.is-active .accordion-title-box::before {
  transform: rotate3d(1, 0, 0, 180deg); }

.accordion-berater .accordion-item {
  margin-bottom: 40px; }
  @media screen and (min-width: 40em) {
    .accordion-berater .accordion-item {
      margin-bottom: 240px; } }

.accordion-berater .accordion-title {
  padding-top: 0; }

.accordion-berater .accordion-title-box .h6 {
  margin-bottom: 0; }

#content-persoenlichkeit-methode.content {
  padding-top: 40px; }
  @media screen and (min-width: 40em) {
    #content-persoenlichkeit-methode.content {
      padding-top: 50px; } }

#instantclick-bar {
  background: #b4ccd8; }

@media screen and (max-width: 47.9375em) {
  .site-footer .sm-order-1 {
    order: 1;
    margin-bottom: 50px; }
  .site-footer .sm-order-2 {
    order: 2; }
  .site-footer .sm-order-3 {
    order: 3; }
  .site-footer .small-text-align {
    text-align: center; } }

.site-footer .footer-logo {
  background-image: url("../img/footer_logo.png");
  background-repeat: no-repeat;
  width: 93px;
  height: 99px;
  display: inline-block;
  display: block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .site-footer .footer-logo {
      background-image: url("../img/2x/footer_logo_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }
  @media screen and (max-width: 47.9375em) {
    .site-footer .footer-logo {
      margin: auto; } }

.site-footer .bottom-content-section {
  position: absolute;
  left: 50%;
  margin-left: -20px;
  top: 85px; }
  .site-footer .bottom-content-section .title {
    top: -30px; }
  @media screen and (max-width: 47.9375em) {
    .site-footer .bottom-content-section {
      position: relative;
      width: 100%;
      left: inherit;
      margin: 40px auto 40px auto;
      top: inherit;
      text-align: center; } }

.site-footer .footer-content-middle {
  margin: 100px auto 100px; }

.site-footer .footer-navi ul {
  margin: 0px; }
  .site-footer .footer-navi ul li {
    text-align: right;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px; }
    .site-footer .footer-navi ul li a {
      color: #fff;
      font-family: 'Roboto-Regular';
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.1em;
      transition: color 0.25s ease-in-out; }
      .site-footer .footer-navi ul li a:hover {
        color: #b4ccd8; }

@media screen and (max-width: 47.9375em) {
  .site-footer .footer-navi ul {
    margin-top: 30px; }
    .site-footer .footer-navi ul li {
      text-align: center; } }

@media screen and (max-width: 47.9375em) {
  .site-footer .footer-icons {
    margin-bottom: 40px; } }

.site-footer .icn-mail {
  display: inline-block;
  background-image: url("../img/icn_mail.png");
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .site-footer .icn-mail {
      background-image: url("../img/2x/icn_mail_2x.png");
      background-size: 48px 98px;
      background-repeat: no-repeat; } }
  .site-footer .icn-mail:after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/icn_mail.png");
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    display: inline-block;
    background-position: 0px -49px;
    transition: all 0.25s ease-in-out; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .site-footer .icn-mail:after {
        background-image: url("../img/2x/icn_mail_2x.png");
        background-size: 48px 98px;
        background-repeat: no-repeat; } }
  .site-footer .icn-mail:hover:after {
    opacity: 1; }

.site-footer .icn-phone {
  display: inline-block;
  background-image: url("../img/icn_phone.png");
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .site-footer .icn-phone {
      background-image: url("../img/2x/icn_phone_2x.png");
      background-size: 48px 98px;
      background-repeat: no-repeat; } }
  .site-footer .icn-phone:after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/icn_phone.png");
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    display: inline-block;
    background-position: 0px -49px;
    transition: all 0.25s ease-in-out; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .site-footer .icn-phone:after {
        background-image: url("../img/2x/icn_phone_2x.png");
        background-size: 48px 98px;
        background-repeat: no-repeat; } }
  .site-footer .icn-phone:hover:after {
    opacity: 1; }

.site-footer .copyright, .site-footer .imprint {
  color: #fff;
  font-family: 'Roboto-Regular';
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
  transition: color 0.25s ease-in-out; }

.site-footer .imprint {
  margin-left: 30px; }

.site-footer .imprint:hover {
  color: #b4ccd8; }

@media screen and (max-width: 47.9375em) {
  .site-footer .footer-content-middle {
    margin: 50px auto 50px auto; } }

.site-footer .footer-netzwerk {
  margin-bottom: 60px;
  padding: 20px 0 0 0;
  border-top: 2px solid #a49e97;
  border-bottom: 2px solid #a49e97;
  color: #fff;
  text-align: center; }
  .site-footer .footer-netzwerk .netzwerk-logo-link {
    position: relative;
    width: 100%;
    display: block;
    max-height: 160px; }
    .site-footer .footer-netzwerk .netzwerk-logo-link:before {
      content: "";
      display: block;
      padding-top: 100%; }
    .site-footer .footer-netzwerk .netzwerk-logo-link .netzwerk-logo {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .site-footer .footer-netzwerk .netzwerk-logo-link .netzwerk-logo:hover {
        background-position: 0 -160px; }
      .site-footer .footer-netzwerk .netzwerk-logo-link .netzwerk-logo.logo-krass {
        background-image: url("../img/netzwerk/netzwerk_krass.png");
        background-repeat: no-repeat;
        width: 160px;
        height: 160px;
        display: inline-block; }
        @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          .site-footer .footer-netzwerk .netzwerk-logo-link .netzwerk-logo.logo-krass {
            background-image: url("../img/2x/netzwerk/netzwerk_krass_2x.png");
            background-size: 100% auto;
            background-repeat: no-repeat; } }
      .site-footer .footer-netzwerk .netzwerk-logo-link .netzwerk-logo.logo-liebeindiewelt {
        background-image: url("../img/netzwerk/netzwerk_liebeindiewelt.png");
        background-repeat: no-repeat;
        width: 160px;
        height: 160px;
        display: inline-block; }
        @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          .site-footer .footer-netzwerk .netzwerk-logo-link .netzwerk-logo.logo-liebeindiewelt {
            background-image: url("../img/2x/netzwerk/netzwerk_liebeindiewelt_2x.png");
            background-size: 100% auto;
            background-repeat: no-repeat; } }
      .site-footer .footer-netzwerk .netzwerk-logo-link .netzwerk-logo.logo-mokom01 {
        background-image: url("../img/netzwerk/netzwerk_mokom01.png");
        background-repeat: no-repeat;
        width: 160px;
        height: 160px;
        display: inline-block; }
        @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          .site-footer .footer-netzwerk .netzwerk-logo-link .netzwerk-logo.logo-mokom01 {
            background-image: url("../img/2x/netzwerk/netzwerk_mokom01_2x.png");
            background-size: 100% auto;
            background-repeat: no-repeat; } }

@keyframes animateInOverlay {
  0% {
    opacity: 0;
    filter: blur(15px); }
  100% {
    filter: blur(0px);
    opacity: 1; } }

@keyframes animateOutOverlay {
  0% {
    filter: blur(0px);
    opacity: 1; }
  100% {
    opacity: 0;
    filter: blur(15px); } }

.content.subpage.overlay, .content.subpage.overlay-imprint {
  overflow: auto;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 100;
  padding-top: 0px;
  padding-bottom: 0px;
  display: none;
  opacity: 0; }
  .content.subpage.overlay section.header, .content.subpage.overlay-imprint section.header {
    position: relative;
    margin-bottom: 110px; }
    @media screen and (max-width: 39.9375em) {
      .content.subpage.overlay section.header .logo, .content.subpage.overlay-imprint section.header .logo {
        width: 48px;
        height: 50px;
        background-size: auto 100%; } }
  .content.subpage.overlay .overlay-headline, .content.subpage.overlay-imprint .overlay-headline {
    margin: auto;
    text-align: center;
    letter-spacing: 0.1em;
    color: #b4ccd8;
    font-family: "Roboto-Regular", Arial, sans-serif; }
    @media screen and (max-width: 39.9375em) {
      .content.subpage.overlay .overlay-headline, .content.subpage.overlay-imprint .overlay-headline {
        font-size: 1.8rem; } }
  .content.subpage.overlay .close-overlay-wrapper, .content.subpage.overlay-imprint .close-overlay-wrapper {
    position: absolute;
    right: 2%;
    top: 50%;
    margin-top: -20px; }
  .content.subpage.overlay h2, .content.subpage.overlay-imprint h2 {
    letter-spacing: 0.2em; }
  .content.subpage.overlay p, .content.subpage.overlay-imprint p {
    letter-spacing: 0.05em; }
  .content.subpage.overlay.animateIn, .content.subpage.overlay-imprint.animateIn {
    display: block;
    animation: animateInOverlay 0.75s ease-in-out forwards; }
    .content.subpage.overlay.animateIn.animateOut, .content.subpage.overlay-imprint.animateIn.animateOut {
      animation: animateOutOverlay 0.75s ease-in-out forwards; }

/* iCheck plugin Minimal skin, grey
----------------------------------- */
.icheckbox_minimal-grey,
.iradio_minimal-grey {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  background: url(../img/icheck_grey.png) no-repeat;
  border: none;
  cursor: pointer; }

.icheckbox_minimal-grey {
  background-position: 0 0; }

.icheckbox_minimal-grey.hover {
  background-position: -20px 0; }

.icheckbox_minimal-grey.checked {
  background-position: -40px 0; }

.icheckbox_minimal-grey.disabled {
  background-position: -60px 0;
  cursor: default; }

.icheckbox_minimal-grey.checked.disabled {
  background-position: -80px 0; }

.iradio_minimal-grey {
  background-position: -100px 0; }

.iradio_minimal-grey.hover {
  background-position: -120px 0; }

.iradio_minimal-grey.checked {
  background-position: -140px 0; }

.iradio_minimal-grey.disabled {
  background-position: -160px 0;
  cursor: default; }

.iradio_minimal-grey.checked.disabled {
  background-position: -180px 0; }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5 / 4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
  .icheckbox_minimal-grey,
  .iradio_minimal-grey {
    background-image: url(../img/2x/icheck_grey_2x.png);
    -webkit-background-size: 200px 20px;
    background-size: 200px 20px; } }

.imprint-overlay .datenschutz {
  margin: 120px 0; }
  .imprint-overlay .datenschutz .h3, .imprint-overlay .datenschutz h4, .imprint-overlay .datenschutz h6 {
    text-transform: uppercase;
    color: #667f8b; }
  .imprint-overlay .datenschutz a {
    color: #667f8b; }
    .imprint-overlay .datenschutz a:hover {
      color: #d2e0e8; }

.h1-beitraege {
  font-size: 24px;
  letter-spacing: 0.2em;
  margin-bottom: 0px;
  line-height: 1.6;
  font-family: 'Roboto-Medium';
  text-transform: uppercase; }

.h3-beitraege {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 30px;
  font-size: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.fachbeitraege.content.subpage {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media screen and (min-width: 40em) {
    .fachbeitraege.content.subpage {
      padding-top: 60px;
      padding-bottom: 20px; } }

.fachbeitraege.content p {
  margin-bottom: 0px; }

.fachbeitraege .beitrag-header {
  position: relative; }

.fachbeitraege p.leistung-text {
  min-height: 0px; }

.fachbeitraege .bg-circle2 {
  position: absolute;
  top: 0;
  right: 20px;
  background-image: url("../img/circles-berater.png");
  background-repeat: no-repeat;
  width: 322px;
  height: 441px;
  display: inline-block; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .fachbeitraege .bg-circle2 {
      background-image: url("../img/2x/circles-berater_2x.png");
      background-size: 100% auto;
      background-repeat: no-repeat; } }

@media screen and (min-width: 40em) {
  .fachbeitraege .teaser-text-right {
    float: right;
    width: 50%; } }

@media screen and (min-width: 64em) {
  .fachbeitraege .teaser-text-right {
    width: 66%;
    padding-right: 16%; } }

.fachbeitraege .teaser-text-right .text-link {
  margin-bottom: 0px; }

@media screen and (min-width: 40em) {
  .fachbeitraege .left-container {
    width: 47%;
    float: left;
    margin-top: -53px; } }

@media screen and (min-width: 64em) {
  .fachbeitraege .left-container {
    width: 30%; } }

@media screen and (min-width: 40em) {
  .fachbeitraege .right-container {
    margin-top: 7px;
    width: 50%;
    float: right; } }

@media screen and (min-width: 64em) {
  .fachbeitraege .right-container {
    width: 30%; } }

.fachbeitraege .picture-left-container, .fachbeitraege .picture-right-container {
  opacity: 0;
  transform: translate(0px, 15px);
  transition: all 0.5s ease-in-out 0.6s;
  background-color: #667f8b;
  max-width: 320px;
  display: block;
  height: 100%; }
  @media screen and (max-width: 47.9375em) {
    .fachbeitraege .picture-left-container, .fachbeitraege .picture-right-container {
      margin-bottom: 20px; } }
  .fachbeitraege .picture-left-container.animate, .fachbeitraege .picture-right-container.animate {
    opacity: 1;
    transform: translate(0px, 0px); }
  .fachbeitraege .picture-left-container img, .fachbeitraege .picture-right-container img {
    transition: all 0.4s ease-in-out;
    width: 100%; }
    .fachbeitraege .picture-left-container img:hover, .fachbeitraege .picture-right-container img:hover {
      opacity: 0.75; }

@media screen and (min-width: 40em) {
  .fachbeitraege .picture-left-container {
    float: right; } }

@media screen and (min-width: 40em) {
  .fachbeitraege .teaser-text-left {
    float: left;
    width: 47%; } }

@media screen and (min-width: 64em) {
  .fachbeitraege .teaser-text-left {
    width: 66%;
    padding-left: 190px; } }

.fachbeitraege .teaser-text-left .text-link {
  margin-bottom: 0px; }

.fachbeitraege .mehr-beitraege {
  margin-top: 10px;
  position: relative;
  height: 120px;
  border-top: 2px solid #f2f1f0;
  width: 95%; }
  @media print, screen and (min-width: 40em) {
    .fachbeitraege .mehr-beitraege {
      margin-top: 35px;
      width: 96%; } }
  @media print, screen and (min-width: 64em) {
    .fachbeitraege .mehr-beitraege {
      width: 1025px; } }

.fachbeitraege .mehr-icon {
  background-image: url("../img/icn_more_hover.png");
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  left: calc(50% - 20px);
  top: 20px;
  z-index: 3; }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .fachbeitraege .mehr-icon {
      background-image: url("../img/2x/icn_more_hover_2x.png");
      background-size: 40px 40px;
      background-repeat: no-repeat; } }
  .fachbeitraege .mehr-icon:after {
    content: "";
    background-image: url("../img/icn_more.png");
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    left: 0%;
    top: 0px;
    transition: all 0.4s ease-in-out;
    z-index: 2; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .fachbeitraege .mehr-icon:after {
        background-image: url("../img/2x/icn_more_2x.png");
        background-size: 40px 40px;
        background-repeat: no-repeat; } }
  .fachbeitraege .mehr-icon:hover:after {
    opacity: 0; }

.fachbeitraege h3 a {
  transition: all 0.25s ease-in-out; }

.fachbeitraege .beitrag-teaser, .fachbeitraege .beitrag-teaser2 {
  padding-top: 23px;
  padding-bottom: 30px;
  position: relative; }
  .fachbeitraege .beitrag-teaser .leistungen-box .h3, .fachbeitraege .beitrag-teaser2 .leistungen-box .h3 {
    opacity: 0;
    transform: translateY(15px);
    transition: transform 0.5s ease-in-out 0.2s, opacity 0.5s ease-in-out 0.2s; }
  .fachbeitraege .beitrag-teaser .leistungen-box .h6, .fachbeitraege .beitrag-teaser2 .leistungen-box .h6 {
    opacity: 0;
    transform: translateY(15px);
    transition: transform 0.5s ease-in-out 0.3s, opacity 0.5s ease-in-out 0.3s; }
  .fachbeitraege .beitrag-teaser .leistungen-box p, .fachbeitraege .beitrag-teaser2 .leistungen-box p {
    opacity: 0;
    transform: translateY(15px);
    transition: transform 0.5s ease-in-out 0.4s, opacity 0.5s ease-in-out 0.4s; }
  .fachbeitraege .beitrag-teaser .leistungen-box .text-link, .fachbeitraege .beitrag-teaser2 .leistungen-box .text-link {
    opacity: 0;
    transform: translateY(15px);
    transition: transform 0.5s ease-in-out 0.5s, opacity 0.5s ease-in-out 0.5s; }
  .fachbeitraege .beitrag-teaser .leistungen-box.animate .h3, .fachbeitraege .beitrag-teaser2 .leistungen-box.animate .h3 {
    opacity: 1;
    transform: translateY(0px); }
  .fachbeitraege .beitrag-teaser .leistungen-box.animate .h6, .fachbeitraege .beitrag-teaser2 .leistungen-box.animate .h6 {
    opacity: 1;
    transform: translateY(0px); }
  .fachbeitraege .beitrag-teaser .leistungen-box.animate p, .fachbeitraege .beitrag-teaser2 .leistungen-box.animate p {
    opacity: 1;
    transform: translateY(0px); }
  .fachbeitraege .beitrag-teaser .leistungen-box.animate .text-link, .fachbeitraege .beitrag-teaser2 .leistungen-box.animate .text-link {
    opacity: 1;
    transform: translateY(0px); }

.fachbeitraege .beitrag-teaser {
  background-color: #f2f1f0; }
  @media screen and (min-width: 40em) {
    .fachbeitraege .beitrag-teaser {
      margin-top: 60px; } }

@media screen and (min-width: 40em) {
  .fachbeitraege .beitrag-teaser2 {
    margin-top: 30px; } }

.text-link {
  transition: 0.5s ease-in-out 0.5s; }
  .text-link.animationComplete {
    transition: all 0.25s ease-in-out !important; }

.fachbeitraege-detail {
  padding-bottom: 0px;
  opacity: 0;
  transition: all ease-in-out 1s;
  /*    .autor+.stern{
        padding-top:20px;
    }*/ }
  .fachbeitraege-detail.animate {
    opacity: 1; }
  @media screen and (max-width: 47.9375em) {
    .fachbeitraege-detail .zurueck {
      margin-bottom: 22px; } }
  .fachbeitraege-detail .paragraph-link {
    color: #667f8b;
    transition: all 0.25s ease-in-out;
    border-bottom: 1px solid #667f8b; }
    .fachbeitraege-detail .paragraph-link:hover {
      color: #d2e0e8;
      transition: all 0.25s ease-in-out;
      border-bottom: 1px solid #d2e0e8; }
  .fachbeitraege-detail .h1-beitraege + p,
  .fachbeitraege-detail .autor + .stern {
    padding-top: 20px; }
  .fachbeitraege-detail .autor {
    padding-top: 30px;
    padding-bottom: 0px;
    /*        padding-bottom:30px;*/ }
  .fachbeitraege-detail .stern {
    font-size: 14px; }
  .fachbeitraege-detail .header-beitrag {
    background-color: #f2f1f0;
    position: relative;
    margin-top: 90px; }
    .fachbeitraege-detail .header-beitrag p {
      padding-top: 30px;
      padding-bottom: 30px;
      margin: 0px; }
  .fachbeitraege-detail .image-container {
    max-width: 500px; }
  .fachbeitraege-detail .header-img {
    width: 100%;
    margin-top: -60px;
    /*padding-top:30px;*/ }
  .fachbeitraege-detail p {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0;
    margin-bottom: 0; }
  .fachbeitraege-detail .p-mobile {
    padding-top: 0px; }
  .fachbeitraege-detail .beitrag-detail-container {
    margin-bottom: 30px; }
    @media screen and (min-width: 40em) {
      .fachbeitraege-detail .beitrag-detail-container {
        margin-top: 60px;
        margin-bottom: 30px; } }
  .fachbeitraege-detail .subheadline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 30px;
    font-size: 20px; }
  .fachbeitraege-detail p + .subheadline {
    padding-top: 22px; }
  .fachbeitraege-detail .video-responsiv {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0; }
    .fachbeitraege-detail .video-responsiv iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute; }
  .fachbeitraege-detail .video-container {
    position: relative;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 500px; }
    @media screen and (max-width: 47.9375em) {
      .fachbeitraege-detail .video-container {
        margin-top: 0px; } }
    .fachbeitraege-detail .video-container video {
      width: 100%;
      max-width: 500px;
      height: auto;
      display: block; }
    .fachbeitraege-detail .video-container a, .fachbeitraege-detail .video-container .preview-video {
      display: block;
      height: 100%;
      background-color: #667f8b;
      width: 100%;
      max-width: 500px;
      height: auto;
      margin-left: auto;
      margin-right: auto; }
      .fachbeitraege-detail .video-container a:hover .video-img, .fachbeitraege-detail .video-container .preview-video:hover .video-img {
        transition: all 0.4s ease-in-out;
        opacity: 0.75; }
    .fachbeitraege-detail .video-container .preview-video {
      position: relative; }
      .fachbeitraege-detail .video-container .preview-video .video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        pointer-events: none;
        opacity: 0; }
      .fachbeitraege-detail .video-container .preview-video .inactive {
        width: 100%;
        opacity: 0.4;
        transition: all 0.4s ease-in-out; }
        .fachbeitraege-detail .video-container .preview-video .inactive + .video-icon {
          opacity: 1; }
        .fachbeitraege-detail .video-container .preview-video .inactive:hover {
          cursor: pointer;
          transition: all 0.4s ease-in-out;
          opacity: 0.75; }
    .fachbeitraege-detail .video-container video {
      opacity: 1; }
      .fachbeitraege-detail .video-container video:focus {
        outline: none; }
  .fachbeitraege-detail .video-img {
    width: 500px;
    opacity: 0.4;
    transition: all 0.4s ease-in-out; }
  .fachbeitraege-detail .video-icon {
    background-image: url("../img/icon_video.png");
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    display: inline-block;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .fachbeitraege-detail .video-icon {
        background-image: url("../img/2x/icon_video_2x.png");
        background-size: 100% auto;
        background-repeat: no-repeat; } }
  .fachbeitraege-detail .kontakt-section {
    padding-top: 27px;
    padding-bottom: 44px; }
  .fachbeitraege-detail .zurueck-container {
    position: relative;
    height: 90px;
    margin-top: 60px;
    border-top: 2px solid #f2f1f0;
    width: 96%; }
    @media print, screen and (min-width: 64em) {
      .fachbeitraege-detail .zurueck-container {
        width: 1025px; } }
  .fachbeitraege-detail .zurueck-icon {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top: 20px; }
  .fachbeitraege-detail .accordion {
    margin-bottom: 0px; }
    .fachbeitraege-detail .accordion li .content {
      padding-bottom: 20px; }
      @media print, screen and (min-width: 40em) {
        .fachbeitraege-detail .accordion li .content {
          padding-bottom: 30px; } }
      @media print, screen and (min-width: 64em) {
        .fachbeitraege-detail .accordion li .content {
          padding-bottom: 40px; } }
    .fachbeitraege-detail .accordion li:last-child .content {
      padding-bottom: 0px; }
  .fachbeitraege-detail .accordion-content .content {
    padding-top: 30px;
    padding-bottom: 0px; }
    .fachbeitraege-detail .accordion-content .content .last-p {
      padding-bottom: 0px; }
    .fachbeitraege-detail .accordion-content .content .bildbeschreibung {
      padding-top: 15px; }
      @media print, screen and (min-width: 40em) {
        .fachbeitraege-detail .accordion-content .content .bildbeschreibung {
          padding-top: 30px; } }
    .fachbeitraege-detail .accordion-content .content img {
      width: 100%; }
      @media screen and (min-width: 40em) {
        .fachbeitraege-detail .accordion-content .content img {
          padding-bottom: 30px; } }
    .fachbeitraege-detail .accordion-content .content .image-container {
      max-width: 500px; }
  .fachbeitraege-detail .accordion-content ul {
    margin-bottom: 0; }
