
/*
*   DEFAULT
*/

body {

}



/*
*   HEADERS
*/

h1, .h1 {
    font-size: 60px;
    font-family: 'Roboto-Medium';
    line-height: 1em;
}

h2, .h2 {
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

h3, .h3 {
    font-size: 24px;
    letter-spacing: 0.2em;
    margin-bottom: 0.8rem;
    font-family: 'Roboto-Medium';
/*    &.animationComplete{
        transition: all 0.25s ease-in-out !important;
    }*/
}

h4, .h4 {
    font-size: 20px;
    letter-spacing: 0.15em;
    margin-bottom: 0.8rem;
    font-family: 'Roboto-Medium';
}

h5 {

}

h6,.h6 {
    font-size: 12px;
    letter-spacing: 0.3em;
    margin-bottom: 0.8rem;
}

.medium{
    font-family: 'Roboto-Medium';
}

.cdark-gray{
    color:$dark-gray;
}

.cdark-blue{
    color:$dark-blue;
}

.cwhite{
    color:$white;
}
.cmedium-blue{
    color:$medium-blue;
}

.text-link-list {
    p {
        margin-bottom: rem-calc(12);
    }
    .text-link {
        display: inline;
        line-height: rem-calc(30);
    }

    .text-link-style {
        display: inline;
        line-height: rem-calc(24);
    }
}

.text-link{
    display: inline-block;
    border-bottom: 2px solid $dark-blue;
    color: $dark-blue;
    font-family: 'Roboto-Regular';
    letter-spacing: 0.1rem;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 6px;
    transition: all 0.25s ease-in-out;
    &.animationComplete{
        transition: all 0.25s ease-in-out !important;
    }
    &:hover, &:focus{
        color: $medium-blue!important;
        border-color: $medium-blue!important;
    }
}

.text-link-style {
    display: inline-block;
    color: $dark-blue;
    font-family: 'Roboto-Regular';
    letter-spacing: 0.1rem;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 6px;
}

.uppercase {
    text-transform: uppercase;
}

.quote {
    font-size: 1rem;
    font-style: italic;
}

/*
*   LINKS AND BUTTONS
*/

a {
    color: inherit;
    &:hover{
        color: $light-blue;
    }
}

button, .button {

}

p{
    color:$dark-gray;

    &.enumeration {
        line-height: 1.8;
    }

    &.last {
        margin-bottom: 0;
    }
}

.small {
    font-family: 'Roboto-Medium';
    letter-spacing: 0.1rem;
    font-size: 12px;
    text-transform: uppercase;
}
.xsmall{
    font-family: 'Roboto-Regular';
    letter-spacing: 0.3rem;
    font-size: 12px;
    text-transform: uppercase;
}

@include breakpoint(large down) {
    h1, .h1{
        font-size: 36px;
        //margin-bottom: 1.5rem;
    }
}