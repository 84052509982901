.leistungen-editorial{
    margin-bottom: 30px;
}

.leistungen-box{
    .h3{
        margin-bottom: 15px;
    }
    .h6{
        margin-bottom: 10px;

        transition: all .25s ease-in-out 0.5s;
        opacity: 0;
        top:20px;
        position: relative;
    }
    .leistung-text {
        min-height: 60px;

        transition: all .25s ease-in-out 0.75s;
        opacity: 0;
        top:20px;
        position: relative;

    }

    .text-link{
        //transition: all 0.25s ease-in-out 1s;
        opacity: 0;
        top:20px;
        position: relative;
    }

    &.animate{
        .leistungen-icn-box-1{
            transform: rotateX(0deg);
            transform-style: preserve-3d;
            perspective: 1500;
            opacity: 1;
        }
        .leistungen-icn-box-2{
            transform: rotateY(0deg);
            transform-style: preserve-3d;
            perspective: 1500;
            opacity: 1;
        }
        .leistungen-icn-box-3{
            transform: rotateY(0deg);
            transform-style: preserve-3d;
            perspective: 1500;
            opacity: 1;
        }

        .leistung-text, .h6, .text-link {
            opacity: 1;
            top:0px;
        }
    }

    //animation speed/delay first box
    &:nth-child(1){
        .h6{
            transition:  all 0.25s ease-in-out 1.25s;
        }
        .leistung-text {
            transition:  all 0.25s ease-in-out 1.25s;
        }
        .text-link{
            transition:  all 1s ease-in-out 1.25s;
        }
    }

    //animation speed/delay second box
    &:nth-child(2){
        .h6{
            transition:  all 0.25s ease-in-out 1.5s;
        }
        .leistung-text {
            transition:  all 0.25s ease-in-out 1.5s;
        }
        .text-link{
            transition:  all 1s ease-in-out 1.5s;
        }
    }

    //animation speed/delay third box
    &:nth-child(3){
        .h6{
            transition: all 0.25s ease-in-out 1.75s;
        }
        .leistung-text {
            transition: all 0.25s ease-in-out 1.75s;
        }
        .text-link{
            transition: all 1s ease-in-out 1.75s;
        }
    }
}


.leistungen-icn-box-1{
    @include image-png ("icn-leistungen-1", 158px, 158px);
    display: block;
    margin: 0px auto 15px auto;
    transform: rotateX(90deg);
    transition: all .5s ease-in-out 0.5s;
    @include breakpoint(small down) {
        transition: all .5s ease-in-out 0.5s;
    }
    opacity: 0;
}

.leistungen-icn-box-2{
    @include image-png ("icn-leistungen-2", 158px, 158px);
    display: block;
    margin: 0px auto 15px auto;
    transform: rotateY(90deg);
    transition: all .5s ease-in-out 0.75s;
    @include breakpoint(small down) {
        transition: all .5s ease-in-out 0.5s;
    }
    opacity: 0;

}

.leistungen-icn-box-3{
    @include image-png ("icn-leistungen-3", 158px, 158px);
    display: block;
    margin: 0px auto 15px auto;
    transform: rotateY(-90deg);
    transition: all .5s ease-in-out 1s;
    @include breakpoint(small down) {
        transition: all .5s ease-in-out 0.5s;
    }
    opacity: 0;

}


@include breakpoint(large down) {
    .leistung-text{
        min-height: inherit;
    }
    .leistungen-box{
        .text-link{
            float: left;
            margin-bottom: 40px;
        }
    }
}



.content.berater {
    $animatedTextChildren: ('.h3', 'h6', 'p', 'a');
    $delayStep: 0.1s;

    $delay: 0.2s;
    @each $child in $animatedTextChildren {
        #{$child} {
            opacity: 0;
            transform: translateY(0px);
            transition: all .5s ease-in-out $delay;
        }
        $delay: $delay + $delayStep;
    }

    .images-berater {
        .front ,
        .back {
            opacity: 0;
            transform: translateY(15px);
            transition: all 0.5s ease-in-out $delay;
        }
    }

    &.animate {
        @each $child in $animatedTextChildren {
            #{$child} {
                opacity: 1;
                transform: translateY(-15px);
            }
        }

        .images-berater {
            .front ,
            .back {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
}